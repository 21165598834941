import clsx from 'clsx';
import { useRef, useState } from 'react';

import { Button } from './Button';

type IProps = {
	onChange?: (val: File | null) => void;
	className?: string;
	csv?: React.Dispatch<React.SetStateAction<{ [key: string]: string }[]>>;
	// setJsonData: Dispatch<SetStateAction<null>>;
	// formValue: string;
};

const FileInput = ({ className, onChange, csv }: IProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [selectedFile, setSelectedFile] = useState<null | string>(null);
	// useEffect(() => {
	// 	if (typeof formValue !== 'string') return;
	// 	if (formValue !== value) setValue(formValue);
	// }, [formValue, value]);

	const uploadAvatar = ({
		target: { files },
	}: React.ChangeEvent<HTMLInputElement>) => {
		if (!files) return;

		const convertCSVToJson = (csvData: any) => {
			const lines = csvData.split('\n');
			const headers: any = lines[0].split(',');
			const result = [];

			for (let i = 1; i < lines.length; i++) {
				const obj: any = {};
				const currentLine = lines[i].split(',');

				for (let j = 0; j < headers.length; j++) {
					if (currentLine[j]) obj[headers[j].trim()] = currentLine[j].trim();
				}

				result.push(obj);
			}
			return result;
		};

		const file = files[0];
		const reader = new FileReader();

		reader.onload = (e: any) => {
			const csvData = e.target.result;
			const jsonDataModel: any = convertCSVToJson(csvData); //output = [{}]
			if (csv) csv(jsonDataModel);
		};

		reader.readAsText(file);

		if (!file) return;
		onChange?.(file);
		setSelectedFile(file.name);
	};

	const onRemovePress = () => {
		onChange?.(null);
		setSelectedFile(null);
		if (inputRef.current) inputRef.current.value = '';
	};
	return (
		<div className="d-flex flex-column gap-4">
			<div className={clsx('', className)}>
				<div className="form-control form-control-solid d-flex align-items-end justify-content-end p-2">
					<Button
						title="Upload"
						type="outline"
						size="small"
						onClick={() => inputRef.current?.click()}
						// className="form-control form-control-solid"
					/>
				</div>
				<input
					ref={inputRef}
					type="file"
					className="d-none"
					accept=".csv"
					onChange={uploadAvatar}
				/>
			</div>
			{selectedFile && (
				<div className="d-flex align-items-center gap-5">
					<span className="fs-4 text-secondary fw-semibold">
						{`${selectedFile}`.length > 40
							? `${selectedFile.slice(0, 40)}...`
							: `${selectedFile}`}
					</span>
					<Button icon="close" size="small" onClick={onRemovePress} />
				</div>
			)}
		</div>
	);
};

export { FileInput };
