import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../_metronic/helpers';

const AuthLayout = () => {
	useEffect(() => {
		const root = document.getElementById('root');
		if (root) {
			root.style.height = '100%';
		}
		return () => {
			if (root) {
				root.style.height = 'auto';
			}
		};
	}, []);

	return (
		<div className="d-flex flex-center h-100 p-5">
			<div className="d-flex flex-column gap-14 p-4 p-sm-8 bg-white rounded-4 shadow-box flex-md-row">
				<img
					className="object-fit-center rounded-3 align-self-center w-100 h-100 mh-600px"
					src={toAbsoluteUrl('/media/misc/auth-screens.png')}
					alt=""
				/>
				<div className="d-flex flex-column justify-content-center align-items-start">
					<div className="d-flex flex-column mb-10 gap-3 align-items-start">
						<span className="text-primary fs-h3 fw-bolder">HelpME</span>
						<span className="fs-h4 fw-semibold text-gray-500">
							Welcome to HelpMe admin panel
						</span>
					</div>
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export { AuthLayout };
