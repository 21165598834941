import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/xCustomStyles.css';
import './_metronic/assets/fonts/icomoon/style.css';
import 'react-calendar/dist/Calendar.css';

import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import Modal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';

//
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import { AuthProvider } from './app/modules/auth';
import { AppRoutes } from './app/routing';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { retry: 1, refetchOnWindowFocus: false, refetchOnMount: false },
	},
});
const container = document.getElementById('root');
// Test build

Modal.setAppElement('#root');

if (container) {
	createRoot(container).render(
		<QueryClientProvider client={queryClient}>
			<MetronicI18nProvider>
				<AuthProvider>
					<AppRoutes />
				</AuthProvider>
			</MetronicI18nProvider>
			{/* TODO: un comment this line <ReactQueryDevtools initialIsOpen={false} /> */}
		</QueryClientProvider>
	);
}
