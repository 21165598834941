import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import Calendar from 'react-calendar';

import { MenuComponent } from '../assets/ts/components';
import { dateHelper } from '../helpers';
import { Button } from './Button';
// import { Dropdown } from './Dropdown';

interface IProps {
	className?: string;
	onChange?: (val: string) => void;
	icon?: string;
	disabled?: boolean;
	formValue?: string;
	placeholder?: string;
	hasError?: boolean;
	small?: boolean;
	isClearable?: boolean;
}

const DatePicker = ({
	className,
	placeholder = 'yyyy-mm-dd',
	disabled = false,
	formValue,
	isClearable,
	onChange = () => {},
}: IProps): JSX.Element => {
	const [selected, setSelected] = useState<Date | null>();
	const selectedFormatted = useMemo(
		() => (selected ? dateHelper.reverseDate(String(selected)) : ''),
		[selected]
	);

	useEffect(() => {
		MenuComponent.reinitialization();
		if (typeof formValue !== 'string') return;
		if (formValue !== selectedFormatted)
			setSelected(formValue ? dayjs(formValue, 'YYYY-MM-DD').toDate() : null);
	}, [formValue]);

	const handleDaySelect = (val: any) => {
		setSelected(val);
		const showedDate = dateHelper.reverseDate(String(val));
		if (showedDate) onChange(showedDate);
		document.body.click();
	};

	return (
		<>
			<button
				disabled={disabled}
				data-kt-menu-trigger="{default: 'click'}"
				data-kt-menu-placement="bottom-end"
				className={clsx(
					'd-flex cursor-pointer form-select form-select-solid rounded-3 justify-content-between align-items-center',
					className
				)}
				style={{
					minHeight: '48px',
				}}
			>
				<span
					className={clsx('fs-5 fw-semibold', {
						'text-gray-500': !selectedFormatted,
						'text-secondary': selectedFormatted,
					})}
				>
					{selectedFormatted || placeholder}
				</span>
				{isClearable && selected && (
					<Button
						type="link"
						onClick={(e) => {
							onChange('');
							e.preventDefault();
							e.stopPropagation();
						}}
						icon="close"
						iconClassName="fs-6"
						className="w-15px h-15px m-0 me-n2 cursor-pointer text-gray-400 z-index-20"
					/>
				)}
			</button>
			<div
				className="menu menu-sub menu-sub-dropdown z-index-10"
				data-kt-menu="true"
				onClick={(e) => e.stopPropagation()}
			>
				<Calendar
					value={formValue}
					onChange={(e) => handleDaySelect(e)}
					nextLabel={<i className="icon-chevron-right" />}
					next2Label={<i className="icon-chevron-double-right" />}
					prevLabel={<i className="icon-chevron-left" />}
					prev2Label={<i className="icon-chevron-double-left" />}
					showNeighboringMonth={false}
				/>
			</div>
		</>
	);
};

export { DatePicker };
