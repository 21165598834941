import type { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import type { FieldT } from '../../../../_metronic/assets/ts';
import { Button, Form } from '../../../../_metronic/partials';
import { useForm } from '../../../../hooks/useForm';
import type { StpLoginParamsT } from '../core/_requests';
import { stpLogin } from '../core/_requests';
import { useAuth } from '../core/Auth';

export const loginForm: FieldT[] = [
	{
		name: 'identifier',
		label: 'Email',
		type: 'input',
		validation: 'required|email',
	},
	{
		name: 'domain',
		label: 'Domain',
		type: 'input',
		validation: 'required|lowerCaseDigit',
	},
	{
		label: 'Password',
		name: 'password',
		type: 'input',
		inputType: 'password',
		validation: 'required|min:4',
	},
];

const StpLogin = () => {
	const { saveAuth } = useAuth();
	const { mutate, isLoading } = useMutation<any, AxiosError, StpLoginParamsT>(
		(_data) => stpLogin(_data),
		{
			onSuccess: (data) => saveAuth(data),
		}
	);

	const { form, handleSubmit } = useForm({
		fields: loginForm,
		onSubmit: (values) => mutate(values),
	});

	return (
		<div className="form w-100 w-sm-300px">
			<Form form={form} fields={loginForm} onSubmit={handleSubmit} />
			<Button.Link
				title="Forget Password?"
				type="link"
				href="/auth/forget"
				className="w-100 align-items-end flex-end mt-4 text-primary"
			/>
			<Button.Link
				href="/auth/otp/login"
				type="link"
				title="OTP Verification"
				className="w-100 mb-5 mt-18 text-primary"
			/>
			<Button
				title="Login"
				onClick={handleSubmit}
				disable={isLoading}
				isLoading={isLoading}
				className="w-100"
			/>
		</div>
	);
};

export { StpLogin };
