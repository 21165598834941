import type {
	AxiosError,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios';
import axios from 'axios';
import toast from 'react-hot-toast';

import { getAuth } from '../../app/modules/auth';
import type { ResponseModel } from './crud-helper/models';

enum StatusCode {
	Unauthorized = 401,
	Forbidden = 403,
	NotFound = 404,
	ValidationError = 422,
	TooManyRequests = 429,
	InternalServerError = 500,
}

type CustomErrorResponse = {
	errorCode: string;
	errorMessage: StatusCode | string;
	detail?: string;
};

type BaseUrlT = 'helpme' | 'baas' | 'upload';

const headers: Readonly<Record<string, string | boolean>> = {
	Accept: 'application/json',
	'Content-Type': 'application/json; charset=utf-8',
	'Access-Control-Allow-Credentials': true,
	'X-Requested-With': 'XMLHttpRequest',
};

const injectToken = (
	config: AxiosRequestConfig<string>
): string | AxiosRequestConfig<string> => {
	try {
		const auth = getAuth();
		if (auth && auth.access_token && config.headers) {
			config.headers.Authorization = `Bearer ${auth.access_token}`;
		}
		return config;
	} catch (error) {
		throw new Error(String(error));
	}
};

class Api {
	private instance: AxiosInstance | null = null;

	readonly baseUrl = process.env.REACT_APP_HELPME_API_URL;

	readonly baasBaseUrl = process.env.REACT_APP_BAAS_API;

	readonly uploadBaseUrl = process.env.REACT_APP_UPLOAD_API;

	http(): AxiosInstance {
		return this.instance != null ? this.instance : this.initHttp('helpme');
	}

	initHttp(type: BaseUrlT) {
		const baseURL =
			type === 'baas'
				? this.baasBaseUrl
				: type === 'upload'
				? this.uploadBaseUrl
				: this.baseUrl;

		const http = axios.create({
			baseURL,
			headers,
		});
		http.interceptors.request.use(
			(config) => injectToken(config),
			(error) => Promise.reject(error)
		);
		http.interceptors.response.use(
			(response: AxiosResponse) => {
				return type === 'baas'
					? (response as AxiosResponse)
					: (response.data as ResponseModel);
			},
			(error) => {
				this.handleError(error);
				throw error;
			}
		);
		this.instance = http;
		return http;
	}

	private handleError(error: AxiosError<CustomErrorResponse>) {
		const { response } = error;
		if (response?.data) {
			const { errorCode, errorMessage, detail } = response.data;
			if (Array.isArray(detail))
				return toast.error(`${detail?.[0].loc?.[0]}: ${detail[0].msg}`);
			if (detail && !Array.isArray(detail)) {
				return toast.error(String(detail?.slice(0, 65)));
			}
			if (errorCode || errorMessage)
				return toast.error(
					`Error with Status Code: ${errorCode} \n Error Message:${errorMessage}`
				);
		}

		const statusToMessage: Record<number, string> = {
			[StatusCode.Unauthorized]: 'Unauthorized',
			[StatusCode.Forbidden]: 'Forbidden',
			[StatusCode.NotFound]: 'NotFound',
			[StatusCode.ValidationError]: 'ValidationError',
			[StatusCode.TooManyRequests]: 'TooManyRequests',
			[StatusCode.InternalServerError]: 'InternalServerError',
		};

		const errorMessage =
			statusToMessage[Number(response?.status)] || 'Something went wrong';
		console.log(statusToMessage, 'error');
		Promise.reject(error);
		return toast.error(errorMessage);
	}
}
const apiInstance = new Api();
const api = new Api().initHttp('helpme');
const baasApi = new Api().initHttp('baas');
const uploadApi = new Api().initHttp('upload');
const uploadBaseUrl = apiInstance.uploadBaseUrl;
const application = 'helpme';
const clicksendId = process.env.REACT_APP_HELPME_CLICK_SEND_ID;
const helpmeOrganization = process.env.REACT_APP_HELPME_ORGANIZATION_NAME;
const helpmeOrganizationId = process.env.REACT_APP_HELPME_ORGANIZATION_ID;

export {
	api,
	application,
	baasApi,
	clicksendId,
	helpmeOrganization,
	helpmeOrganizationId,
	uploadApi,
	uploadBaseUrl,
};
