import { useAuth } from '../../../../app/modules/auth';
import { HeaderUserMenu } from './header-menus/HeaderUserMenu';

const Navbar = () => {
	const { currentUser } = useAuth();

	return (
		<div className="app-navbar flex-row align-items-center justify-content-between flex-lg-grow-1">
			<span className="fs-base text-gray-500 mx-3">
				Welcome {currentUser?.firstName}
			</span>
			<div className="d-flex gap-3">
				{/* <div className="app-navbar-item">
					<ThemeModeSwitcher toggleBtnClass="btn-active-light" />
				</div> */}
				<div className="app-navbar-item">
					<div
						className="cursor-pointer"
						data-kt-menu-trigger="{default: 'click'}"
						data-kt-menu-attach="parent"
						data-kt-menu-placement="bottom-end"
					>
						<i className="icon-profile-circle fs-2qx text-gray-500" />
					</div>
					<HeaderUserMenu />
				</div>
			</div>
		</div>
	);
};

export { Navbar };
