import { Buffer } from 'buffer';

import type { SelectOptionType } from '../assets/ts';
import { dateHelper } from './dateHelper';

export type LabelValueT = {
	label?: string;
	value?: string | null;
	hasCopy?: boolean;
};
class GeneralHelper {
	convertArr<T extends { id: string }>(
		data: Array<T>,
		key: keyof T,
		modifier?: keyof T
	): SelectOptionType[] {
		if (!data) return [];
		return data.map((item: T) => ({
			label: String(item[key]),
			value: item.id || String(item[modifier as keyof typeof item]),
		}));
	}

	checkInsuranceNumber(birthdate: string, insuranceNumber: string) {
		const isoDate = dateHelper.convertToIsoDate(birthdate);
		const birthdateString = isoDate.split('T')[0].split('-');
		if (insuranceNumber) {
			return (
				insuranceNumber.slice(4, 10) ===
				`${birthdateString[2]}${birthdateString[1]}${birthdateString[0].slice(
					2
				)}`
			);
		}
	}

	lockScroll = () => {
		document.body.style.overflowY = 'hidden';
		document.documentElement.style.overflowY = 'hidden';
		if (window.innerWidth > 1024) document.body.style.paddingRight = '6px';
	};

	enableScroll = () => {
		document.body.style.overflowY = 'auto';
		document.documentElement.style.overflowY = 'auto';
		if (window.innerWidth > 1024) document.body.style.paddingRight = '0px';
	};

	random128bitGenerator = () => {
		let result = '';
		for (let i = 0; i < 8; i++) {
			result += String.fromCharCode(Math.random() * 0x10000);
		}
		return result;
	};

	nonceGenerator = () => {
		return Buffer.from(this.random128bitGenerator()).toString('base64');
	};
}

const generalHelper = new GeneralHelper();
export { generalHelper };
