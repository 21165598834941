import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getDomainDetailByName } from '../../../../app/modules/apps/domain-management/create-domain/core/_requests';
import { useAuth } from '../../../../app/modules/auth';
import { helpmeOrganization } from '../../../helpers/apiHelper';
import { Button } from '../../../partials';

export const SidebarFooter = () => {
	const { currentUser } = useAuth();
	const navigate = useNavigate();
	const { mutate, isLoading } = useMutation(
		() => getDomainDetailByName({ id: currentUser?.domain || '' }),
		{
			onSuccess(response) {
				navigate(`/apps/domain-management/${response.id}`);
			},
		}
	);
	return (
		<div className="app-sidebar-footer" id="kt_app_sidebar_footer">
			<div className="app-sidebar-menu overflow-hidden flex-column-fluid">
				<div className="d-flex menu-item flex-column login-info-wrapper p-3 rounded-3 bg-gray-100 mx-3">
					<div className="d-flex align-items-center gap-2 login-info-title-wrapper">
						<i className="icon-info-circle login-info-icon fs-2 text-gray-500 menu-icon" />
						<span className="fs-5 fw-normal text-gray-500 menu-title">
							Login Info
						</span>
					</div>
					<div className="flex-column login-info gap-4">
						{[
							{
								label: 'Organization:',
								value: helpmeOrganization,
							},
							{
								label: 'Domain:',
								value: currentUser?.domain || '',
								isLink: true,
							},
						].map(({ label, value, isLink }, index) => (
							<div
								className="d-flex justify-content-between align-items-center"
								key={index}
							>
								<span className="fs-5 fw-normal text-gray-500 opacity-75">
									{label}
								</span>
								{isLink ? (
									<Button
										isLoading={isLoading}
										size="small"
										type="link"
										title={value}
										onClick={() => mutate()}
									/>
								) : (
									<span className="fs-5 fw-semibold text-gray-500">
										{value}
									</span>
								)}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
