import clsx from 'clsx';
import { useEffect } from 'react';

import { generalHelper } from '../helpers/generalHelper';
import { Button } from './Button';

type IProps = {
	children?: React.ReactNode;
	className?: string;
	onClose?: () => void;
	title?: string;
	titleClassName?: string;
	isLoading?: boolean;
	contentClassName?: string;
	bodyClassName?: string;
	hasBlurBackground?: boolean;
};

const ModalWrapper = ({
	children,
	className,
	onClose,
	titleClassName,
	title,
	contentClassName,
	bodyClassName,
	hasBlurBackground,
}: IProps) => {
	useEffect(() => {
		generalHelper.lockScroll();
		document.body.classList.add('modal-open');
		return () => {
			generalHelper.enableScroll();
			document.body.classList.remove('modal-open');
		};
	}, []);
	return (
		<>
			<div
				className={clsx('modal fade show d-block', className)}
				role="dialog"
				tabIndex={-1}
				aria-modal="true"
			>
				<div
					className={clsx(
						'modal-dialog modal-dialog-centered',
						contentClassName
					)}
				>
					<div className="modal-content">
						<div className="modal-header p-7">
							<h2
								className={clsx(
									'fw-semibold fs-base text-secondary',
									titleClassName
								)}
							>
								{title}
							</h2>
							<Button icon="close" onClick={onClose} />
						</div>
						<div className={clsx('modal-body scroll-y p-0', bodyClassName)}>
							{children}
						</div>
					</div>
				</div>
			</div>
			<div
				className={clsx('modal-backdrop fade show', {
					'backdrop-blur': hasBlurBackground,
				})}
			/>
		</>
	);
};

export { ModalWrapper };
