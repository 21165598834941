import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import sha256 from 'crypto-js/sha256';
import randomBytes from 'randombytes';

import { application, baasApi } from '../../../../_metronic/helpers/apiHelper';
import type { AuthModel, LoginModel, UserModel } from './_models';

type StpLoginParamsT = {
	identifier: string;
	password: string;
	domain: string;
};

type OtpLoginParamsT = {
	identifier: string;
	domain: string;
};

type VerifyParamsT = {
	token: string;
	regId: string;
	identifier: string;
};

type GenerateTokenT = {
	identifier: string;
	server_timestamp: string;
	registration_id: string;
	server_nonce: string;
	password: string;
	nonce: string;
	timestamp: string;
	isOtp?: boolean;
};

const generateToken = ({
	identifier,
	server_timestamp,
	registration_id,
	server_nonce,
	password,
	nonce,
	timestamp,
	isOtp,
}: GenerateTokenT) => {
	const firstHash = sha256(identifier + nonce + server_timestamp);
	const secondHash = sha256(registration_id + server_nonce + timestamp);
	let code: string;
	if (!isOtp) {
		const shaPassword = sha256(password);
		code = Base64.stringify(shaPassword);
	} else {
		code = password;
	}
	const tokenHash = hmacSHA256(firstHash.concat(secondHash), code);
	const token = Base64.stringify(tokenHash);
	return token;
};

const loginData = {
	application,
	nonce: randomBytes(128 / 8).toString('base64'),
	timestamp: '' + new Date().getTime(),
};

const otpLogin = async (params: OtpLoginParamsT) => {
	const data = await baasApi.post<LoginModel>('user/login/otp/request', {
		...params,
		...loginData,
	});
	return data;
};

const verifyOtp = async (params: VerifyParamsT) => {
	const response = await baasApi.post<AuthModel>(
		'user/login/otp/confirm',
		params
	);
	return response;
};

const forgetPasswordConfirm = async (params: VerifyParamsT) => {
	const response = await baasApi.post<AuthModel>(
		'user/forget-pwd/otp/confirm',
		params
	);
	return response;
};

const setNewPassword = async (params: any) => {
	const response = await baasApi.post<AuthModel>(
		'user/forget-pwd/set-pwd',
		params
	);
	return response;
};

const stpLogin = async (params: StpLoginParamsT) => {
	const { data } = await baasApi.post<LoginModel>('user/login/stp/request', {
		identifier: params.identifier,
		domain: params.domain,
		...loginData,
	});
	if (data.registration_id) {
		return verifyStp({
			token: generateToken({ ...data, ...params, ...loginData }),
			regId: data.registration_id,
			identifier: params.identifier,
		});
	}
};

const verifyStp = async (params: VerifyParamsT) => {
	const { data } = await baasApi.post<AuthModel>(
		'user/login/stp/confirm',
		params
	);
	return data;
};

const forgetPassword = async (params: VerifyParamsT) => {
	const { data } = await baasApi.post<AuthModel>(
		'user/forget-pwd/otp/request',
		{ ...params, ...loginData }
	);
	return data;
};

const getUserByToken = () => {
	return baasApi.get<UserModel>('user/details');
};

const logoutCurrentUser = (token: string) => {
	return baasApi.post<UserModel>('user/logout', {
		fcm_token: token,
	});
};

export {
	forgetPassword,
	forgetPasswordConfirm,
	generateToken,
	getUserByToken,
	loginData,
	logoutCurrentUser,
	otpLogin,
	setNewPassword,
	stpLogin,
	verifyOtp,
	verifyStp,
};
export type { GenerateTokenT, OtpLoginParamsT, StpLoginParamsT, VerifyParamsT };
