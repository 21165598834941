const QUERIES = {
	USERS_LIST: 'users-list',
	DEVICES_LIST: 'devices-list',
	UNALOCATED_DEVICES_LIST: 'unallocated-devices-list',
	GROUPS_LIST: 'groups-list',
	DOMAINS_LIST: 'domains-list',
	MEMBER_LIST: 'member-list',
	WORK_GROUP_LIST: 'workgroup-list',
	NOTIFICATION_LIST: 'notification-list',
	TEMPLATE_LIST: 'template-list',
	POLICE_LIST: 'police-list',
	WORKGROUP_MEMBER_LIST: 'workgroup-member-list',
	TEMPLATE_RULE_LIST: 'template-rule-list',
	WORK_GROUP_RULE_LIST: 'workgroup-rule-list',
	USER_REPORTS: 'user-reports',
	USER_CASES: 'user-cases',
	ALL_CASES: 'all-cases',
	USER_PERMISSIONS: 'user-permissions',
	ALL_PERMISSIONS: 'all-permissions',
	WORK_GROUP_MEMBER_LIST: 'workgroup-member-list',
	ORGANIZATION_LIST: 'organization-list',
	ORGANIZATION_DETAIL: 'organization-detail',
	WORK_GROUP_CASE_LIST: 'workgroup-case-list',
	ORGANIZATION_ADMINS: 'organization_admins',
	DOMAIN_ROLE_LIST: 'domain-role-list',
};

export { QUERIES };
