import type { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { App } from '../App';
import { AuthPage, Logout, useAuth } from '../modules/auth';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import { PrivateRoutes } from './PrivateRoutes';

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
	const { currentUser } = useAuth();

	return (
		<BrowserRouter basename={PUBLIC_URL}>
			<Routes>
				<Route element={<App />}>
					<Route path="error/*" element={<ErrorsPage />} />
					<Route path="logout" element={<Logout />} />
					{currentUser ? (
						<>
							<Route path="/*" element={<PrivateRoutes />} />
						</>
					) : (
						<>
							<Route path="auth/*" element={<AuthPage />} />
							<Route path="*" element={<Navigate to="/auth" />} />
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export { AppRoutes };
