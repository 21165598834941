const Loading = () => {
	const styles = {
		top: 'calc(50% - 2rem)',
		left: 'calc(50% - 4rem)',
	};

	return (
		<div
			className="w-auto m-0 text-secondary bg-white rounded-1 fw-semibold shadow-sm py-4 px-8 position-absolute align-middle"
			style={{ ...styles }}
		>
			Processing...
		</div>
	);
};

export { Loading };
