import clsx from 'clsx';
import { useEffect, useState } from 'react';
import type {
	ControlProps,
	MenuPlacement,
	Props,
	StylesConfig,
} from 'react-select';
import Select, { components } from 'react-select';

import type { LabelValue, SelectOptionType } from '../assets/ts';

interface IProps {
	placeholder?: string;
	icon?: string;
	className?: string;
	name?: string;
	disabled?: boolean;
	options?: SelectOptionType[];
	formValue?: string;
	hasError?: boolean;
	onChange?: (val: string) => void;
	onBlur?: () => void;
	isClearable?: boolean;
	isSearchable?: boolean;
	menuPlacement?: MenuPlacement;
}

type ControlT = ControlProps<SelectOptionType>;

const CountrySelect = ({
	placeholder = 'Select ...',
	className,
	name,
	formValue = '',
	options = [],
	onChange = () => false,
	onBlur = () => false,
	disabled,
	isClearable = false,
	isSearchable = true,
	menuPlacement = 'bottom',
}: IProps): JSX.Element => {
	const [selected, setSelected] = useState<LabelValue | null>(null);

	useEffect(() => {
		if (typeof formValue !== 'string') return;
		if (formValue !== selected?.value) {
			const finded = options.find((i) => i.value === formValue);
			if (finded) {
				setSelected(
					{
						label:
							`${finded?.label.split(' ')[0]} ${finded?.label.split(' ')[1]}` ||
							'',
						value: finded?.value || '',
					} || ''
				);
			}
		}
	}, [formValue, options]);

	const customStyles = (): StylesConfig<SelectOptionType> => ({
		input: (provided) => ({
			...provided,
			marginLeft: '0',
			padding: 0,
			margin: 0,
		}),
		menu: (provided) => ({
			...provided,
			minWidth: '309px',
			boxShadow:
				'0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
		}),
	});

	const customFilter = (option: SelectOptionType, searchText: string) => {
		if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
			return true;
		}
		return false;
	};
	const CustomSelect = (props: Props<SelectOptionType, false>) => {
		return <Select menuShouldScrollIntoView={false} {...props} />;
	};

	const Control = (props: ControlT) => (
		<components.Control
			{...props}
			getStyles={() => ({ margin: 0, minHeight: '48px', zIndex: 10000 })}
			className={clsx(
				'gap-x-6 d-flex align-items-center px-2 cursor-pointer rounded-3 bg-gray-100'
			)}
		>
			{props.children}
		</components.Control>
	);

	return (
		<CustomSelect
			isSearchable={isSearchable}
			isClearable={isClearable}
			menuPosition="absolute"
			menuPlacement={menuPlacement}
			menuShouldScrollIntoView={false}
			options={options}
			filterOption={customFilter}
			value={selected}
			components={{ Control, IndicatorSeparator: null }}
			placeholder={placeholder}
			isDisabled={disabled}
			className={clsx('', className)}
			name={name}
			styles={customStyles()}
			blurInputOnSelect
			onBlur={onBlur}
			onChange={(item) => {
				onChange(String(item?.value));
			}}
		/>
	);
};

export { CountrySelect };
