import clsx from 'clsx';
import type React from 'react';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import type { InputTypes } from '../assets/ts';

type InputElements = HTMLTextAreaElement | HTMLInputElement;
interface IProps {
	placeholder?: string;
	icon?: string;
	className?: string;
	formValue?: string;
	name?: string;
	suffix?: React.ReactElement | string | null;
	prefix?: React.ReactElement | string | null;
	autoDirection?: boolean;
	disabled?: boolean;
	inputType?: InputTypes;
	maxLength?: number;
	reverse?: boolean;
	small?: boolean;
	hasError?: boolean;
	min?: number;
	max?: number;
	onChange?: (val: string) => void;
	onEnter?: (val: string) => void;
	onBlur?: () => void;
	onFocus?: () => void;
	isTouched?: boolean;
	isSubmitted?: boolean;
	hasCopyValue?: boolean;
}

const Input = ({
	placeholder,
	className,
	name,
	suffix = null,
	prefix = null,
	disabled = false,
	icon,
	inputType = 'text',
	maxLength,
	min,
	max,
	onChange = () => false,
	onBlur = () => false,
	onFocus = () => false,
	formValue,
	hasCopyValue,
}: IProps): JSX.Element => {
	const [value, setValue] = useState<string>('');
	const [isActive, setIsActive] = useState<boolean>(false);
	const [showPass, setShowPass] = useState<boolean>(false);
	const [copied, setCopied] = useState<boolean>(false);
	let inputMode: React.HTMLAttributes<HTMLLIElement>['inputMode'] = 'text';

	const numericInput = inputType === 'number';
	useEffect(() => {
		if (typeof formValue !== 'string') return;
		if (formValue !== value) setValue(formValue);
	}, [formValue, value]);

	const changeValue = (newValue: string): void => {
		if (inputType === 'number' && Number.isNaN(Number(newValue))) return;
		onChange(newValue);
		setValue(newValue);
	};

	const printValue = (): string => {
		const val = value;
		return val;
	};

	const props = {
		disabled,
		maxLength,
		name,
		className: clsx(
			'flex flex-fill resize-none form-control p-0 form-control-flush',
			{
				'h-100px': inputType === 'textarea',
				// 'is-invalid': (isTouched || isSubmitted) && hasError,
				// 'is-valid': (isTouched || isSubmitted) && !hasError,
			}
		),
		type: 'text',
		pattern: '',
		value: printValue(),
		onChange: ({ target }: React.ChangeEvent<InputElements>) =>
			changeValue(target.value),
		onFocus: () => {
			onFocus();
			setIsActive(true);
		},
		onBlur: () => {
			setIsActive(false);
			onBlur();
		},
	};

	switch (inputType) {
		case 'number':
			inputMode = 'numeric';
			props.pattern = '[0-9]*';
			break;
		case 'password':
			props.type = showPass ? 'text' : 'password';
			break;
		default:
			break;
	}

	useEffect(() => {
		if (
			numericInput &&
			max !== undefined &&
			min !== undefined &&
			!isActive &&
			value
		) {
			if (Number(value) > max) {
				changeValue(String(max));
			}
			if (Number(value) < min) {
				changeValue(String(min));
			}
		}
	}, [value, isActive]);

	return (
		<div
			className={clsx(
				'd-flex position-relative z-index-0 gap-2 form-control form-control-solid',
				{
					active: isActive,
				},
				className
			)}
		>
			{prefix}
			{icon && (
				<i
					className={clsx(
						`icon-${icon}`,
						'd-flex align-self-center fs-3 fs-sm-4 text-gray-300'
					)}
				/>
			)}
			<div className="d-flex flex-fill">
				{inputType === 'textarea' ? (
					<textarea {...props} placeholder={placeholder} />
				) : inputType === 'datepicker' ? (
					<input
						{...props}
						inputMode={inputMode}
						placeholder={placeholder}
						type="date"
					/>
				) : inputType === 'datetime-local' ? (
					<input
						{...props}
						placeholder={placeholder}
						type="datetime-local"
						autoComplete="off"
					/>
				) : (
					<input
						{...props}
						inputMode={inputMode}
						placeholder={placeholder}
						autoComplete="off"
					/>
				)}
			</div>

			{inputType === 'password' ? (
				<i
					onClick={() => setShowPass(!showPass)}
					className={clsx(
						showPass ? 'icon-hide' : 'icon-show',
						'd-flex align-items-center fs-3 fs-sm-4 text-gray-300 ms-2',
						className
					)}
				/>
			) : null}
			{/* TODO: vaghti focus mishe range bg icon bayad ba input match beshe ham inja ham baraye password */}
			{hasCopyValue ? (
				<CopyToClipboard
					text={String(formValue)}
					onCopy={() => setCopied(true)}
				>
					<button
						type="button"
						disabled={!formValue?.length}
						className="d-flex outine-none border-0 p-0 align-items-center bg-transparent ms-2"
					>
						<i
							className={clsx('icon-copy fs-3 fs-sm-4', {
								'text-gray-400': !formValue,
								'text-secondary': copied,
							})}
						/>
					</button>
				</CopyToClipboard>
			) : null}
			{suffix}
		</div>
	);
};

export { Input };
