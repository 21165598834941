import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

type ButtonSize = 'normal' | 'small' | 'large' | 'tiny';
type ButtonTypes = 'primary' | 'outline' | 'link' | 'light';

interface ButtonProps {
	title?: string;
	titleClassName?: string;
	icon?: string;
	iconClassName?: string;
	className?: string;
	isLoading?: boolean;
	reverse?: boolean;
	noPadding?: boolean;
	link?: boolean;
	iconPath?: number;
	disable?: boolean;
	theme?: string;
	type?: ButtonTypes;
	actionType?: 'submit' | 'reset' | 'button' | undefined;
	size?: ButtonSize;
	href?: string;
	onChange?: (val: File) => void;
	onClick?: (
		event: React.MouseEvent<HTMLAnchorElement> &
			React.MouseEvent<HTMLButtonElement>
	) => void;
}

const Button = ({
	title,
	titleClassName,
	icon,
	iconClassName,
	className = '',
	type = 'primary',
	size = 'normal',
	actionType = 'button',
	isLoading = false,
	disable = false,
	href,
	noPadding,
	theme,
	reverse,
	iconPath,
	onClick = () => false,
}: ButtonProps): JSX.Element => {
	const btnRef = useRef<HTMLButtonElement>(null);
	useEffect(() => {
		if (isLoading && !href)
			btnRef.current?.setAttribute('data-kt-indicator', 'on');
		else btnRef.current?.removeAttribute('data-kt-indicator');
	}, [isLoading]);
	return (
		<button
			ref={btnRef}
			className={clsx(
				'btn d-flex flex-center gap-2',
				{
					'btn-outline btn-outline-primary bg-white': type === 'outline',
					'btn-light': type === 'light',
					'btn-primary': type === 'primary',
					'btn-link py-0': type === 'link',
					'btn-outline btn-outline-secondary':
						type === 'outline' && theme === 'secondary',
					disable,
					'btn-sm': size === 'small',
					'btn-lg': size === 'large',
					'p-0 btn-flush': noPadding,
					'flex-row-reverse': reverse,
					'btn-icon btn-sm btn-active-icon-primary w-40px h-40px': !title && icon,
				},
				className
			)}
			disabled={disable || isLoading}
			type={actionType}
			onClick={onClick}
		>
			{title && (
				<span
					className={clsx(
						'btn-title',
						{
							'fw-semibold fs-base': size === 'normal',
							'fw-normal fs-sm': size === 'small',
							'fw-bolder fs-base': size === 'large',
							'fw-normal fs-6': size === 'tiny',
						},
						titleClassName
					)}
				>
					{title}
				</span>
			)}
			{icon && (
				<i
					className={clsx(
						`btn-icon icon-${icon}`,
						{
							'fs-7': size === 'tiny',
							'fs-6': size === 'small',
							'fs-4': size === 'normal',
							'fs-3': size === 'large',
						},
						iconClassName
					)}
				>
					{[...Array(iconPath || 0)].map((_, index) => (
						<span key={index + 1} className={`path${index + 1}`}></span>
					))}
				</i>
			)}
			{isLoading && (
				<span className="indicator-progress">
					<span className="spinner-border spinner-border-sm align-middle" />
				</span>
			)}
		</button>
	);
};

const LinkButton = (props: ButtonProps): JSX.Element => (
	<Link to={props.href || ''}>
		<Button {...props} />
	</Link>
);

Button.Link = LinkButton;

export { Button };
export type { ButtonProps };
