import { Route, Routes } from 'react-router-dom';

import { AuthLayout } from './AuthLayout';
import {
	ForgetPassword,
	ForgetVerify,
	NewPassword,
	OtpLogin,
	StpLogin,
	Verify,
} from './components';

const AuthPage = () => (
	<Routes>
		<Route element={<AuthLayout />}>
			<Route path="/stp/login" element={<StpLogin />} />
			<Route path="/otp/login" element={<OtpLogin />} />
			<Route path="/otp/verify" element={<Verify />} />
			<Route path="/forget" element={<ForgetPassword />} />
			<Route path="/forget/verify" element={<ForgetVerify />} />
			<Route path="/new-password" element={<NewPassword />} />
			<Route index element={<StpLogin />} />
		</Route>
	</Routes>
);

export { AuthPage };
