import clsx from 'clsx';
import { useEffect, useState } from 'react';

import type { SelectOptionType } from '../assets/ts';

type IProps = {
	disabled?: boolean;
	options?: SelectOptionType[];
	className?: string;
	itemClassName?: string;
	formValue?: string | number | null;
	onChange?: (val: string | number) => void;
};

const RadioGroup = ({
	options,
	onChange = () => false,
	className,
	disabled,
	formValue,
	itemClassName,
}: IProps): JSX.Element => {
	const [active, setActive] = useState<string | number>('');

	useEffect(() => {
		if (typeof formValue !== 'string' && typeof formValue !== 'number') return;
		if (formValue !== active) setActive(formValue);
	}, [formValue, options]);

	const handleChange = (val: string | number) => {
		if (disabled) return;
		setActive(val);
		onChange(val);
	};

	return (
		<div className={clsx('d-flex gap-4 h-45px', className)}>
			{options?.map(({ label, value }, index) => (
				<div
					onClick={() =>
						handleChange(
							typeof value === 'string' ? String(value) : Number(value)
						)
					}
					key={index}
					className={clsx(
						'd-flex cursor-pointer flex-row gap-2 form-check form-check-solid form-check-custom flex-center align-items-center',
						itemClassName
					)}
				>
					<input
						onChange={() => false}
						className="form-check-input w-20px h-20px cursor-pointer"
						name={String(index)}
						type="radio"
						value={label}
						checked={formValue === value}
						disabled={disabled}
					/>
					<label className="fs-5 fw-normal text-secondary cursor-pointer">
						{label}
					</label>
				</div>
			))}
		</div>
	);
	// 	key={index}
	// 	className={clsx(
	// 		'flex gap-x-2 justify-center items-center transition duration-300 cursor-pointer group',
	// 		itemClassName
	// 	)}
	// 	onClick={() => handleChange(value)}
	// >
	// 	<div
	// 		className={clsx(
	// 			'w-5 h-5 flex justify-center items-center rounded-full border-2 group-hover:border-gray-150 border-gray-100 bg-base-1 transition duration-300',
	// 			{
	// 				'!border-secondary-1': active === value,
	// 				'bg-gray-50 border-none': disabled,
	// 			}
	// 		)}
	// 	>
	// 		<span
	// 			className={clsx(
	// 				'w-2.5 rounded-full h-2.5 transition duration-300 bg-transparent scale-0',
	// 				{
	// 					'!bg-secondary-1': active === value,
	// 					'!scale-100': active === value,
	// 					'!bg-gray-100': disabled,
	// 				}
	// 			)}
	// 		/>
	// 	</div>
	// 	<span
	// 		className={clsx(
	// 			'text-gray-600 tp-lead2 group-hover:text-text-1 transition duration-300',
	// 			{
	// 				'!text-secondary-1': active === value,
	// 				'!text-gray-50': disabled,
	// 			}
	// 		)}
	// 	>
	// 		{label}
	// 	</span>
	// </div>
	// ))}
	// </div>
	// );
};

export { RadioGroup };
