import type { AuthModel } from './_models';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';
const getAuth = (): AuthModel | undefined => {
	if (!localStorage) {
		return;
	}

	const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
	if (!lsValue) {
		return;
	}

	try {
		const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
		if (auth) {
			return auth;
		}
	} catch (error) {
		console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
	}
};

const setAuth = (auth: AuthModel) => {
	if (!localStorage) {
		return;
	}

	try {
		const lsValue = JSON.stringify(auth);
		localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
	} catch (error) {
		console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
	}
};

const removeAuth = () => {
	if (!localStorage) {
		return;
	}

	try {
		localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
	} catch (error) {
		console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
	}
};

export { AUTH_LOCAL_STORAGE_KEY, getAuth, removeAuth, setAuth };
