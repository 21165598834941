import clsx from 'clsx';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import type { FieldT, SelectOptionType } from '../assets/ts';
import { DatePicker } from './DatePicker';
import { FileInput } from './FileInput';
import { FormItem } from './FormItem';
import { InfiniteSelectPaginate } from './InfiniteSelectPaginate';
import { Input } from './Input';
import { PhoneCountry } from './PhoneCountry';
import { RadioGroup } from './RadioGroup';
import { SelectBox } from './SelectBox';

interface FormProps {
	fields: FieldT[];
	form: UseFormReturn<FieldValues, any>;
	className?: string;
	wrapperClassName?: string;
	itemClassName?: string;
	options?: Record<string, SelectOptionType[]>;
	onSubmit?: () => void;
	disabled?: boolean;
}

const Form = ({
	fields = [],
	form,
	className,
	wrapperClassName,
	itemClassName,
	onSubmit,
	options,
	disabled,
}: FormProps) => {
	const renderField = ({ type, ...props }: FieldT): React.ReactNode => {
		if (!type) return null;
		const Component = {
			input: Input,
			select: SelectBox,
			file: FileInput,
			radio: RadioGroup,
			datepicker: DatePicker,
			phoneCountry: PhoneCountry,
			hidden: null,
			paginateSelect: InfiniteSelectPaginate,
		}[type];
		if (!Component) return null;
		return (
			<Component {...props} options={props.options || options?.[props.name]} />
		);
	};

	if (!FormItem) return null;
	return (
		<form noValidate onSubmit={onSubmit} className={wrapperClassName}>
			<div className={clsx('row gx-9 gx-sm-5 form-item-wrapper', className)}>
				{fields.map((item) => {
					const fieldName = item.name;
					return (
						<FormItem
							key={item.name}
							name={item.name}
							label={item.label}
							description={item.description}
							disabled={disabled || item.disabled}
							form={
								form && {
									control: form.control,
									error: form.getFieldState(fieldName).error,
									errors: form.formState.errors,
									watch: form.watch,
								}
							}
							validation={item.validation}
							className={clsx(
								'm-0',
								{
									'col-12 col-sm-3 col-md-2': item.span === 2,
									'col-12 col-md-5 col-xl-3': item.span === 3,
									'col-12 col-md-4': item.span === 4,
									'col-12 col-md-5': item.span === 5,
									'col-12 col-md-6': item.span === 6,
									'col-12 col-md-7': item.span === 7,
									'col-12 col-md-8': item.span === 8,
									'col-12 col-md-9': item.span === 9,
									'col-12 col-md-10': item.span === 10,
									'col-12 col-md-11': item.span === 11,
									'col-12': !item.span || item.span === 12,
								},
								itemClassName
							)}
						>
							{renderField(item)}
						</FormItem>
					);
				})}
			</div>
		</form>
	);
};

export { Form };
export type { FormProps };
