import type { AxiosError } from 'axios';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import type { FieldT } from '../../../../_metronic/assets/ts';
import { Button, VerificationCode } from '../../../../_metronic/partials';
import { useCountdown } from '../../../../hooks';
import { useForm } from '../../../../hooks/useForm';
import {
	forgetPassword,
	forgetPasswordConfirm,
	generateToken,
	loginData,
} from '../core/_requests';
import { useAuth } from '../core/Auth';

const verifyFields: FieldT[] = [
	{
		name: 'code',
		label: 'Code',
		type: 'input',
		validation: 'required',
	},
];

const ForgetVerify = () => {
	const [verificationCode, setVerificationCode] = useState<string>('');
	const { timeLeft, secondsLeft, setSeconds } = useCountdown(120);
	const { otpData, setOtpData } = useAuth();
	const navigate = useNavigate();

	const { mutate, isLoading } = useMutation<any, unknown, any>(
		(_data) => forgetPasswordConfirm(_data),
		{
			onSuccess: () => navigate('/auth/new-password'),
		}
	);

	const { form, handleSubmit } = useForm({
		fields: verifyFields,
		onSubmit: (values) => {
			mutate({
				token: generateToken({
					...otpData,
					...loginData,
					isOtp: true,
					identifier: otpData.identifier,
					password: values.code,
				}),
				regId: otpData?.registration_id,
				identifier: otpData.identifier,
			});
		},
	});

	const { mutate: mutateResend, isLoading: resendLoading } = useMutation<
		any,
		AxiosError,
		any
	>((_data) => forgetPassword(_data), {
		onSuccess: (response, values) => {
			setOtpData({
				...response,
				...values,
			});
			setSeconds(120);
			toast.success('Verification Code Successfully send');
		},
	});

	const onSubmitForm = (value: string) => {
		setVerificationCode(value);
		form.setValue('code', value);
		handleSubmit();
	};

	useEffect(() => {
		if (!otpData.identifier) navigate('/auth/otp/login');
	}, []);

	return (
		<div className="form w-100 w-sm-300px">
			<VerificationCode
				autoFocus
				onChange={setVerificationCode}
				onComplete={onSubmitForm}
				containerClassName="mt-3"
			/>
			<div className="d-flex flex-center flex-column">
				<span
					className={clsx('my-6 fs-base fw-semibold text-gray-500', {
						'text-gray-300': !secondsLeft,
					})}
				>
					{timeLeft}
				</span>
				<Button
					title="Resend OTP"
					onClick={() =>
						mutateResend({
							domain: otpData.domain,
							identifier: otpData.identifier,
						})
					}
					type="link"
					className="text-primary"
					noPadding
					isLoading={resendLoading}
					disable={!!secondsLeft}
				/>
			</div>
			<Button
				title="Verify"
				onClick={() => onSubmitForm(verificationCode)}
				disable={verificationCode.length < 5 || isLoading}
				isLoading={isLoading}
				className="mt-18 w-100"
			/>
		</div>
	);
};

export { ForgetVerify };
