import { useState } from 'react';
import { useMutation } from 'react-query';

import { uploadApi } from '../_metronic/helpers/apiHelper';

type IProps = {
	onSuccess: (val: string) => void;
};

const useUpload = ({ onSuccess }: IProps) => {
	const [progress, setProgress] = useState<number>(0);
	const config = {
		headers: {
			'Content-Type': 'multipart/form-data',
		},

		onUploadProgress: (progressEvent: ProgressEvent) => {
			setProgress(
				Math.round((100 * progressEvent.loaded) / progressEvent.total)
			);
		},
	};

	const mutateFn = async (file: File): Promise<any> => {
		const formData = new FormData();
		formData.append('file', file);
		const { data } = await uploadApi.post('up.php', formData, config);
		return data.file_name;
	};

	const { mutateAsync, isLoading } = useMutation<string, unknown, any>(
		mutateFn,
		{
			onSuccess: (res) => onSuccess(res),
		}
	);

	return {
		upload: (file: File) => mutateAsync(file),
		progress,
		isUploading: isLoading,
	};
};

export { useUpload };
