import clsx from 'clsx';
import toast from 'react-hot-toast';

type Props = {
	data: {
		title: string;
		body: JSX.Element;
		isActive: boolean;
		disabled?: boolean;
	}[];
	className?: string;
};

const Accordion = ({ data, className }: Props): JSX.Element => {
	// const [activeIds, setActiveIds] = useState<number[]>([]);
	// const handlePress = (id: number) => {
	// 	const isExisted = activeIds.includes(id);
	// 	if (isExisted) setActiveIds(activeIds.filter((i) => i !== id));
	// 	else setActiveIds([...activeIds, id]);
	// };

	const showToastError = () => {
		toast.error('Please Select "Timer" First');
	};

	return (
		<div
			className={clsx(
				'd-flex flex-column position-relative overflow-hidden',
				className
			)}
		>
			{data.map(({ body, title, isActive, disabled }, index) => {
				const isLastItem = index + 1 === data.length;
				return (
					<div key={index} className="d-flex flex-column">
						<div className="accordion">
							<div className="accordion-item border-0">
								<h2 className="accordion-header">
									{disabled && (
										<span
											className="position-absolute w-100 h-100"
											style={{ zIndex: 1000 }}
											onClick={showToastError}
										/>
									)}
									<button
										className="accordion-button bg-white px-8 py-4 collapsed gap-5 shadow-0 rounded-0"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target={`#kt_accordion${index + 1}`}
										aria-expanded="false"
										disabled={disabled}
										// onClick={() => handlePress(index)}
									>
										<div
											className={clsx(
												'w-50px h-50px d-flex justify-content-center align-items-center rounded-full',
												{
													'border-primary': isActive,
													'border-primary opacity-25': !isActive,
												}
											)}
											style={{ border: '1px solid' }}
										>
											<span className="fs-4 fw-bold text-primary">
												{index + 1}
											</span>
										</div>
										{/* <div className="d-flex hstack justify-content-center align-items-center flex-column">
											{!isLastItem && (
												<span className="w-2px h-100 opacity-100 border border-dashed align-self-center d-none d-md-flex" />
											)}
										</div> */}
										<span
											className={clsx('fs-4', {
												'fw-semibold': true,
												// 'fw-semibold': !activeIds.includes(index),
												// 'fw-bold': activeIds.includes(index),
											})}
										>
											{title}
										</span>
									</button>
								</h2>
								<div
									id={`kt_accordion${index + 1}`}
									className="accordion-collapse collapse"
									style={{ padding: '0 6.25rem' }}
								>
									<div className="accordion-body pt-0 px-0 d-flex justify-content-start">
										{body}
									</div>
								</div>
							</div>
						</div>
						<span
							className="w-100 h-1px"
							style={{
								opacity: 0.1,
								background:
									'linear-gradient(-90deg,#000 0%, rgba(0,0,0,0.00) 100%)',
							}}
						/>
					</div>
				);
			})}
		</div>
	);
};

export { Accordion };
