import type { Dispatch, FC, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { QUERIES, type WithChildren } from '../../../../_metronic/helpers';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import {
	initialOrganization,
	type OrganizationT,
} from '../../apps/organization/core/_models';
import { getOrganizationDetail } from '../../apps/organization/core/_requests';
import type { AuthModel, LoginModel, UserModel } from './_models';
import { getUserByToken } from './_requests';
import * as authHelper from './AuthHelpers';

export type OtpDataT = LoginModel & {
	identifier: string;
	domain: string;
} & {
	apiFunction: (params: any) => Promise<any>;
};

type AuthContextProps = {
	auth: AuthModel | undefined;
	saveAuth: (auth: AuthModel | undefined) => void;
	currentUser: UserModel | undefined;
	setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
	logout: () => void;
	otpData: OtpDataT;
	setOtpData: Dispatch<SetStateAction<OtpDataT>>;
	refetchOrganization: () => void;
	organization: OrganizationT;
	isOrganizationOwner: boolean;
	organizationLoading: boolean;
};

const initialOtpData = {
	server_nonce: '',
	registration_id: '',
	server_timestamp: '',
	identifier: '',
	domain: '',
	apiFunction: () => new Promise(() => {}),
};

const initAuthContextPropsState: AuthContextProps = {
	auth: authHelper.getAuth(),
	saveAuth: () => {},
	currentUser: undefined,
	setCurrentUser: () => {},
	logout: () => {},
	otpData: initialOtpData,
	setOtpData: () => {},
	refetchOrganization: () => {},
	organization: initialOrganization,
	isOrganizationOwner: false,
	organizationLoading: false,
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
	return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
	const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
	const [otpData, setOtpData] = useState<OtpDataT>(initialOtpData);
	const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
	const saveAuth = async (auth: AuthModel | undefined) => {
		setAuth(auth);
		if (auth) {
			authHelper.setAuth(auth);
			const userResponse = await getUserByToken();
			setCurrentUser(userResponse.data);
		} else {
			authHelper.removeAuth();
		}
	};

	const logout = () => {
		saveAuth(undefined);
		setCurrentUser(undefined);
	};

	const {
		data,
		refetch: refetchOrganization,
		isLoading,
	} = useQuery(QUERIES.ORGANIZATION_DETAIL, () => getOrganizationDetail());

	return (
		<AuthContext.Provider
			value={{
				auth,
				saveAuth,
				currentUser,
				setCurrentUser,
				logout,
				otpData,
				setOtpData,
				refetchOrganization,
				organization: data?.data || {},
				isOrganizationOwner: data?.data?.owner.id === currentUser?.id,
				organizationLoading: isLoading,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

const AuthInit: FC<WithChildren> = ({ children }) => {
	const didRequest = useRef(false);
	const [showSplashScreen, setShowSplashScreen] = useState(true);
	const { auth, logout, setCurrentUser } = useAuth();

	const requestUser = async () => {
		try {
			if (!didRequest.current) {
				const data: any = await getUserByToken();
				if (data.data) {
					setCurrentUser(data.data);
				}
			}
		} catch (error) {
			if (!didRequest.current) {
				logout();
			}
		} finally {
			setShowSplashScreen(false);
		}
		return () => (didRequest.current = true);
	};

	const { refetch } = useQuery(
		QUERIES.ORGANIZATION_DETAIL,
		() => getOrganizationDetail(),
		{
			refetchOnMount: true,
		}
	);

	useEffect(() => {
		if (auth && auth.access_token) {
			requestUser();
			refetch();
		} else {
			logout();
			setShowSplashScreen(false);
		}
	}, [auth]);

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthInit, AuthProvider, useAuth };
