import clsx from 'clsx';
import { useEffect, useRef } from 'react';

type Props = { isLoading?: boolean; className?: string; color?: string };

const Spinner = ({ isLoading, className, color }: Props) => {
	const loadingRef = useRef<any>(null);
	useEffect(() => {
		if (isLoading) loadingRef.current?.setAttribute('data-kt-indicator', 'on');
		else loadingRef.current?.removeAttribute('data-kt-indicator');
	}, [isLoading]);

	return (
		<div className={className} ref={loadingRef}>
			<span className="indicator-progress">
				<span
					className={clsx(
						'spinner-border spinner-border-sm align-middle',
						`text-${color}`
					)}
				/>
			</span>
		</div>
	);
};

export { Spinner };
