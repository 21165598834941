import { Toaster } from 'react-hot-toast';

const ToastContainer = () => {
	return (
		<Toaster
			position="bottom-right"
			toastOptions={{
				className: 'p-4 fs-5 fw-bold overflow-hidden',
				duration: 5000,
				style: {
					background: '#363636',
					color: '#fff',
				},
				success: {
					style: {
						backgroundColor: '',
						color: '',
					},
					duration: 3000,
				},
				error: {
					style: {
						backgroundColor: '#fff',
						border: '1px solid #FF4A4A',
						color: '#FF4A4A',
						boxShadow: '0px 4px 16px rgba(255,74,74, 0.4)',
					},
					duration: 5000,
				},
			}}
		/>
	);
};
export { ToastContainer };
