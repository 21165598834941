import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import type { SelectOptionType } from '../assets/ts';

interface IProps {
	className?: string;
	disabled?: boolean;
	onChange?: (val: number) => void;
	tabs: SelectOptionType[];
	activeTab?: number;
	withQuery?: boolean;
	itemClassName?: string;
}

const tabClassName =
	'nav-link me-0 ms-0 cursor-pointer text-gray-400 fw-normal text-active-secondary px-2';

const TabBar = ({
	className,
	onChange = () => false,
	tabs = [],
	activeTab,
	withQuery = false,
	itemClassName,
}: IProps): JSX.Element => {
	const location = useLocation();
	const [active, setActive] = useState<number>();
	const changeTab = (newTab: number): void => {
		setActive(newTab);
		onChange(newTab);
	};

	useEffect(() => {
		if (activeTab !== undefined && activeTab !== active) {
			changeTab(activeTab);
		}
	}, [activeTab]);
	return (
		<ul
			className={clsx(
				'nav nav-stretch nav-line-tabs gap-2 nav-line-tabs-2x border-transparent fs-4 flex-nowrap rounded-3',
				className
			)}
		>
			{tabs.map((item, index) => (
				<li className="nav-item" key={index}>
					{withQuery ? (
						<Link
							className={clsx(
								tabClassName,
								{
									'active fw-bold': location.pathname.includes(
										String(item.value)
									),
								},
								itemClassName
							)}
							to={String(item.value)}
						>
							{item.label}
						</Link>
					) : (
						<div
							className={clsx(
								tabClassName,
								{
									'active fw-bold': active === item.value,
								},
								itemClassName
							)}
							onClick={() => changeTab(Number(item.value))}
						>
							{item.label}
						</div>
					)}
				</li>
			))}
		</ul>
	);
};

export { TabBar };
