import type { FC } from 'react';

import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { Button } from '../../../../_metronic/partials';

const Error500: FC = () => {
	return (
		<div className="d-flex flex-column flex-center">
			<h1 className="fw-bolder fs-2hx text-gray-900 mb-4">System Error</h1>
			<div className="fw-semibold fs-4 text-gray-700 mb-7">
				Something went wrong! Please try again later.
			</div>
			<img
				src={toAbsoluteUrl('/media/auth/500-error.png')}
				className="mw-100 mh-300px theme-light-show"
				alt=""
			/>
			<img
				src={toAbsoluteUrl('/media/auth/500-error-dark.png')}
				className="mw-100 mh-300px theme-dark-show"
				alt=""
			/>
			<Button.Link title="Return Home" href="/" />
		</div>
	);
};

export { Error500 };
