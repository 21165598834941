import type { AxiosResponse } from 'axios';

import type {
	ID,
	Response,
	ResponseModel,
} from '../../../../../../_metronic/helpers';
import {
	application,
	baasApi,
} from '../../../../../../_metronic/helpers/apiHelper';
import type {
	DomainApplicationT,
	DomainT,
} from '../../domain-list/core/_models';
import type { UpdateUserDomainQueryArgs } from './types';

const getDomainDetail = (id: ID): Promise<any> => {
	return baasApi
		.get(`/domains/details/by-id/${id}`)
		.then((response) => response);
};

const getDomainRoleList = (domain: string | undefined): Promise<any> => {
	return baasApi
		.post(`/user/role/list/${domain}/${application}`, {
			user_ids: [],
		})
		.then((response) => response);
};

const getDomainDetailByName = ({ id }: { id: string }): Promise<any> => {
	return baasApi
		.get(`/domains/details/by-name/${id}`)
		.then((response) => response.data);
};

const createDomain = (params: any): Promise<DomainT | undefined> => {
	return baasApi
		.post(`/domains/create`, params)
		.then((response: AxiosResponse<Response<DomainT>>) => response.data)
		.then((response: Response<DomainT>) => response.data);
};

const createDomainApplication = (
	params: any
): Promise<Response<{ domainapplication_id: string }> | undefined> => {
	return baasApi
		.post(`/applications/domainapplication/create`, params)
		.then((response: any) => response);
};

const connectDomainToApplication = (
	params: any
): Promise<DomainApplicationT | undefined> => {
	return baasApi
		.post(
			`/notification/email/domainapplication-emailnotification/create`,
			params
		)
		.then(
			(response: AxiosResponse<Response<DomainApplicationT>>) => response.data
		)
		.then((response: Response<DomainApplicationT>) => response.data);
};
const updateUserDomainRole = (
	params: UpdateUserDomainQueryArgs
): Promise<ResponseModel> => {
	return baasApi
		.post(
			`/user/role/update/${params.domain}/${application}/${params.user_id}`,
			{ role: params.role }
		)
		.then((response: AxiosResponse<Response<string>>) => response.data);
};

const updateDomain = (params: any): Promise<DomainT | undefined> => {
	return baasApi
		.post(`/domains/update/${params.domainId}`, params)
		.then((response: AxiosResponse<Response<DomainT>>) => response)
		.then((response) => response.data);
};

export {
	connectDomainToApplication,
	createDomain,
	createDomainApplication,
	getDomainDetail,
	getDomainDetailByName,
	getDomainRoleList,
	updateDomain,
	updateUserDomainRole,
};
