import clsx from 'clsx';
import { useEffect, useState } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import CountryPhoneCodeList from '../../_metronic/helpers/countryPhoneCode.json';
import type { SelectOptionType } from '../assets/ts';
import { CountrySelect } from './CountrySelect';
import { Input } from './Input';

interface IProps {
	className?: string;
	formValue?: string;
	name?: string;
	disabled?: boolean;
	small?: boolean;
	onChange?: (val: string) => void;
	hasError?: boolean;
	onBlur?: () => void;
	onFocus?: () => void;
	form?: UseFormReturn<FieldValues, any>;
}

const PhoneCountry = ({
	className,
	disabled = false,
	small = true,
	onBlur = () => false,
	onFocus = () => false,
	hasError,
	form,
	name,
	formValue,
	onChange = () => false,
}: IProps): JSX.Element => {
	const countryPhoneCodes = CountryPhoneCodeList.map((i) => ({
		label: `${i.emoji} ${i.dial_code} ${i.name.split(',')[0]}`,
		value: i.dial_code,
	}));

	const [phoneCode, setPhoneCode] = useState<string>('');
	const [phoneNumber, setPhoneNumber] = useState<string>('');
	const phone = `${phoneCode}${phoneNumber}`;
	useEffect(() => {
		if (typeof formValue !== 'string') return;
		if (formValue !== phone) {
			const defaultPhoneCode = countryPhoneCodes.filter((i: SelectOptionType) =>
				formValue.startsWith(String(i.value))
			)?.[0];
			setPhoneCode(String(defaultPhoneCode.value));
			setPhoneNumber(formValue.split(defaultPhoneCode.value)[1]);
		}
	}, [formValue]);

	useEffect(() => {
		onChange(phone);
		form?.setValue(String(name), phone);
	}, [phoneCode, phoneNumber]);

	return (
		<div className={clsx('d-flex gap-2', className)}>
			<CountrySelect
				formValue={phoneCode}
				onChange={(val: string) => {
					setPhoneCode(val);
				}}
				disabled={disabled}
				options={countryPhoneCodes || []}
				hasError={hasError}
				className="w-100"
				placeholder="33"
			/>
			<Input
				formValue={phoneNumber}
				onChange={(val) => setPhoneNumber(val.replace(/^0+/, ''))}
				disabled={disabled}
				inputType="number"
				placeholder="123-456-0000"
				small={small}
				onBlur={onBlur}
				onFocus={onFocus}
				hasError={hasError}
			/>
		</div>
	);
};

export { PhoneCountry };
