import type { AxiosResponse } from 'axios';

import type { Response } from '../../../../../_metronic/helpers';
import {
	application,
	baasApi,
	helpmeOrganization,
	helpmeOrganizationId,
} from '../../../../../_metronic/helpers/apiHelper';
import type {
	GetDomainAdminsArgs,
	OrganizationT,
	UserRolesResponseT,
} from './_models';

const getOrganizationDetail = (): Promise<any> => {
	return baasApi
		.get(`organizations/details?identifier=${helpmeOrganization}`)
		.then((response) => response);
};

const getDomainAdmins = (
	params: GetDomainAdminsArgs
): Promise<Response<UserRolesResponseT[]>> => {
	return baasApi
		.post(`/user/role/list/${application}/${params.user_ids}`, {
			user_ids: params.user_ids,
		})
		.then(
			(response: AxiosResponse<Response<UserRolesResponseT[]>>) => response.data
		);
};

const updateOrganization = (
	params: any
): Promise<OrganizationT | undefined> => {
	return baasApi
		.post(`organizations/update/${helpmeOrganizationId}`, params)
		.then((response: AxiosResponse<Response<OrganizationT>>) => response.data);
};

export { getDomainAdmins, getOrganizationDetail, updateOrganization };
