import clsx from 'clsx';

import type { PaginationState, QueryState } from '../helpers';
import { Spinner } from './Spinner';

type IProps = {
	isLoading?: boolean;
	pagination: Partial<PaginationState>;
	updateState: (updates: Partial<QueryState>) => void;
};
const ListPagination = ({ isLoading, pagination, updateState }: IProps) => {
	return (
		<div className="row">
			<div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
			<div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
				<div>
					{!pagination.isEndReached && (
						<ul className="pagination">
							<li
								className={clsx('page-item', {
									disabled: isLoading || pagination.isEndReached,
								})}
							>
								{isLoading ? (
									<span className="page-link">
										<Spinner isLoading={isLoading} />
									</span>
								) : pagination.isEndReached ? null : (
									<div
										className="page-link cursor-pointer fs-5"
										onClick={() =>
											updateState?.({
												last: pagination.last,
												page: Number(pagination?.page) + 1,
											})
										}
									>
										Load More
									</div>
								)}
							</li>
						</ul>
					)}
				</div>
			</div>
		</div>
	);
};

export { ListPagination };
