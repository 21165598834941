export const AccessDenied = () => {
	return (
		<div className="d-flex justify-content-center align-items-center my-5 py-5">
			<div className="alert alert-danger d-flex align-items-center p-5 mb-10">
				{/* <span className='svg-icon svg-icon-2hx svg-icon-primary me-3'>...</span> */}

				<div className="d-flex flex-column">
					<h5 className="mb-1">Access Denied</h5>
					<span>
						You do not have permission to access this page. Ask super admin for
						more details !
					</span>
				</div>
			</div>
		</div>
	);
};
