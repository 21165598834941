import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

type Props = {
	codeLength?: number;
	className?: string;
	size?: string | number;
	containerClassName?: string;
	formValue?: string;
	autoFocus?: boolean;
	onChange: (val: string) => void;
	onComplete: (val: string) => void;
};

const VerificationCode = ({
	codeLength = 5,
	size = '70px',
	className,
	containerClassName,
	formValue,
	autoFocus = false,
	onChange = () => false,
	onComplete = () => false,
}: Props): JSX.Element => {
	const inputs = useRef<Array<HTMLInputElement | null>>([]);
	const [value, setValue] = useState<string>('');
	const [active, setActive] = useState<number>(0);

	// useEffect(() => {
	//   window.addEventListener('paste', (event) => {
	//     event.preventDefault();
	//     const paste = event.clipboardData.getData('text');
	//     setValue(paste);
	//   });
	// }, []);

	// useEventListener({ type: 'DOMContentLoaded', listener: onPaste });
	useEffect(() => {
		if (autoFocus) {
			inputs.current[0]?.focus();
			setActive(0);
		}
	}, []);

	useEffect(() => {
		const lastVal = value.replace(/ /g, '');
		if (lastVal.length === codeLength) onComplete(lastVal);
		else onChange(lastVal);
	}, [value]);

	useEffect(() => {
		if (typeof formValue !== 'string') return;
		if (formValue !== value) setValue(formValue);
	}, [formValue, value]);

	const handleBackSpace = (e: React.KeyboardEvent<object>, index: number) => {
		if (
			e.code === 'Backspace' &&
			index > 0 &&
			!value[index]?.replace(/ /g, '')
		) {
			e.preventDefault();
			inputs.current[index - 1]?.focus();
			setActive(index - 1);
		}
	};

	const handleClick = (index: number) => {
		if (
			!value[index]?.replace(/ /g, '') &&
			!value[index - 1]?.replace(/ /g, '')
		) {
			const toIndexValue = value.slice(0, index).replace(/ /g, '');
			inputs.current[toIndexValue.length]?.focus();
		}
		setActive(index);
	};

	const handleChange = (
		{ target }: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		if (Number.isNaN(Number(target.value))) return;
		const char = target.value[target.value.length - 1] || ' ';
		const newValue = [...new Array(codeLength)]
			.map((_, n) => (n === index ? char : value[n] || ''))
			.join('');
		setValue(newValue);
		if (char === ' ') return;
		if (index + 1 < codeLength) {
			inputs.current[index + 1]?.focus();
			setActive(index + 1);
		} else {
			inputs.current[index]?.blur();
			setActive(index);
		}
	};

	return (
		<div
			className={clsx(
				'd-flex flex-fluid position-relative w-100 gap-2',
				containerClassName
			)}
		>
			{[...new Array(codeLength)].map((_, index) => {
				return (
					<input
						key={index}
						ref={(ref) => {
							inputs.current[index] = ref;
						}}
						value={value[index]?.replace(/ /g, '') || ''}
						onChange={(e) => handleChange(e, index)}
						onKeyDown={(e) => handleBackSpace(e, index)}
						onClick={() => handleClick(index)}
						className={clsx(
							'd-flex form-control position-relative h-50px w-100 text-center h-100 rounded-3 border border-2 fs-4 fw-bold',
							{
								'border-primary': index === active,
								'border-gray-200': index !== active,
							},
							className
						)}
						style={{ height: size }}
					/>
				);
			})}
		</div>
	);
};
export { VerificationCode };
