import type { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { TabBar } from '../../../../_metronic/partials';
import type { OtpLoginParamsT } from '../core/_requests';
import { otpLogin } from '../core/_requests';
import { useAuth } from '../core/Auth';
import { EmailLogin } from './EmailLogin';
import { MobileLogin } from './MobileLogin';

const OtpLogin = () => {
	const [activeTab, setActiveTab] = useState(1);
	const navigate = useNavigate();
	const { setOtpData } = useAuth();

	const { mutate, isLoading } = useMutation<
		AxiosResponse,
		AxiosError,
		OtpLoginParamsT
	>((_data) => otpLogin(_data), {
		onSuccess: (response, values) => {
			setOtpData({
				...response.data,
				...values,
			});
			navigate('/auth/otp/verify');
		},
	});

	return (
		<div className="form w-100 w-sm-300px">
			<TabBar
				activeTab={activeTab}
				onChange={setActiveTab}
				tabs={[
					{ label: 'Email', value: 1 },
					{
						label: 'Phone Number',
						value: 2,
					},
				]}
				itemClassName="w-100 justify-content-center"
				className="mb-10"
			/>
			{activeTab === 1 ? (
				<EmailLogin handleCallApi={mutate} isLoading={isLoading} />
			) : (
				<MobileLogin handleCallApi={mutate} isLoading={isLoading} />
			)}
		</div>
	);
};

export { OtpLogin };
