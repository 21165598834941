import type { Dispatch, SetStateAction } from 'react';
import type { InfiniteData } from 'react-query';

export type ID = undefined | null | number | string;

export type PaginationState = {
	last: number | string;
	page: number;
	count: 50 | 1;
	isEndReached: boolean;
};

export type SortState = {
	sortBy?: string;
	order?: 'asc' | 'desc';
};

export type FilterState = {
	filter?: any;
};

export type SearchState = {
	search?: string;
};

export type ResponseModel = {
	notifications?: any;
	groupsName?: any;
	data?: any;
	list?: any;
	domains?: any;
	message?: string;
	responseTime?: string;
	payload?: {
		message?: string;
		responseTime?: string;
		errors?: {
			[key: string]: Array<string>;
		};
		pagination?: PaginationState;
	};
};

export type Response<T> = ResponseModel & T;

// export type Response<T> = {
//   data?: T
//   payload?: {
//     message?: string
//     errors?: {
//       [key: string]: Array<string>
//     }
//     pagination?: PaginationState
//   }
// }

export type QueryState = PaginationState &
	SortState &
	FilterState &
	SearchState & { domain: string };

export type QueryRequestContextProps = {
	state: QueryState;
	updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
	last: -1,
	page: 1,
	count: 50,
	isEndReached: false,
	domain: '',
};

export const initialQueryRequest: QueryRequestContextProps = {
	state: initialQueryState,
	updateState: () => {},
};

export type QueryResponseContextProps<T> = {
	response?: Response<T> | undefined;
	refetch: () => void;
	isLoading: boolean;
	query: string;
};

export type InfiniteQueryResponseContextProps<T> = {
	response?: InfiniteData<Response<T>> | undefined;
	refetch: () => void;
	isLoading: boolean;
	query: string;
	fetchNextPage: () => void;
	fetchPreviousPage: () => void;
	hasNextPage?: boolean;
	hasPreviousPage?: boolean;
};

export const initialQueryResponse = {
	refetch: () => {},
	isLoading: false,
	query: '',
};

export const initialInfinitQueryResponse = {
	refetch: () => {},
	isLoading: false,
	query: '',
	fetchNextPage: () => {},
	fetchPreviousPage: () => {},
	hasNextPage: false,
	hasPreviousPage: false,
};

export type ListViewContextProps<T> = {
	selected?: Array<ID>;
	onSelect?: (selectedId: ID) => void;
	clearSelected?: () => void;
	// NULL => (CREATION MODE) | MODAL IS OPENED
	// NUMBER => (EDIT MODE) | MODAL IS OPENED
	// UNDEFINED => MODAL IS CLOSED
	itemIdForUpdate?: T;
	setItemIdForUpdate: Dispatch<SetStateAction<T>>;
	itemForAddMessage?: T;
	setItemForAddMessage?: Dispatch<SetStateAction<T>>;
};

export const initialListView: ListViewContextProps<any> = {
	selected: [],
	onSelect: () => {},
	clearSelected: () => {},
	setItemIdForUpdate: () => {},
	setItemForAddMessage: () => {},
};
