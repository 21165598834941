import clsx from 'clsx';
import type React from 'react';
import { Children, cloneElement, isValidElement, useState } from 'react';
import type {
	Control,
	FieldError,
	FieldErrors,
	FieldValues,
	Merge,
	UseFormWatch,
} from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { formHelper } from '../helpers';

interface IProps {
	children: React.ReactNode;
	name: string;
	form?: {
		control: Control<FieldValues, any>;
		error?: FieldError | Merge<FieldError, FieldErrors<any>>;
		errors?: FieldErrors<FieldValues>;
		watch: UseFormWatch<FieldValues>;
	};
	className?: string;
	label?: string;
	validation?: string;
	deps?: string;
	description?: string;
	pattern?: RegExp;
	disabled?: boolean;
}

const FormItem = ({
	children,
	name,
	className,
	label,
	form,
	validation,
	description,
	pattern,
	disabled,
}: IProps) => {
	const [rules, setRules] = useState(
		formHelper.generateRule(validation, form?.watch, pattern)
	);
	const fieldName = name;
	const fieldError = form?.error;
	const hasError = Boolean(fieldError);
	const childrenWithProps = Children.map(children, (child) => {
		if (isValidElement(child) && form) {
			return (
				<Controller
					render={({ field, fieldState, formState }) => {
						const FieldItem = cloneElement(child as React.ReactElement<any>, {
							onChange: (value: any) => {
								field.onChange(value);
							},
							onBlur: () => {
								field.onBlur();
							},
							onFocus() {
								if (validation?.includes('deps:'))
									setRules(
										formHelper.generateRule(validation, form?.watch, pattern)
									);
							},
							formValue: field.value,
							hasError,
							isTouched: fieldState.isTouched,
							isSubmitted: formState.isSubmitted,
							name: fieldName,
							disabled,
						});
						return FieldItem;
					}}
					control={form?.control}
					name={fieldName}
					rules={rules}
				/>
			);
		}
		return child;
	});
	return (
		<div className={clsx('d-flex flex-column', className)}>
			{label && (
				<div className="d-flex gap-1 align-items-center mb-2">
					<span className="form-label mb-0">{label}</span>
					{description && (
						<span className="form-label fs-5 fw-light mb-0">{description}</span>
					)}
				</div>
			)}
			{childrenWithProps}
			<span
				className={clsx('d-flex opacity-0 z-index-n1 ms-2 text-danger fs-5', {
					'opacity-100 z-index-0 mt-2': hasError,
				})}
			>
				{fieldError?.message?.toString() || ''}
			</span>
		</div>
	);
};

export { FormItem };
