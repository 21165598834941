class TextHelper {
	putCommas = (value?: string): string | undefined => {
		if (!value || typeof value === 'undefined') return value;
		return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	etcString(str?: string, count: number = 10): string {
		if (!str) return '';
		return str.length < count ? str : `${str.slice(0, count - 2)}...`;
	}

	commifyNumber(str: string | null | undefined | number): string {
		if (Number(str) > 10000) return '10000+';
		if (Number(str) > 5000) return '5000+';
		if (Number(str) > 1000) return '1000+';
		if (Number(str) > 500) return '500+';
		if (Number(str) > 100) return '100+';
		return String(str);
	}

	putZero(str: number | null | undefined) {
		if (!str) return '';
		return `0${str}`.slice(-2);
	}

	capitalizeString(string: string) {
		const lowercaseString = string.toLowerCase();
		return lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
	}

	toPascalCase(str: string) {
		if (!str) return '';
		return str.replace(/\w+/g, (w: any) => {
			if (w && w[0]) return w[0].toUpperCase() + w.slice(1).toLowerCase();
		});
	}
}

const textHelper = new TextHelper();
export { textHelper };
