import clsx from 'clsx';
import { useTable } from 'react-table';

import {
	initialQueryState,
	KTSVG,
	type PaginationState,
	type QueryState,
	toAbsoluteUrl,
} from '../helpers';
import { Button } from './Button';
import { ListPagination } from './ListPagintation';
import { Loading } from './Loading';

type IProps = {
	data: any;
	columns: any;
	isLoading?: boolean;
	pagination: Partial<PaginationState>;
	updateState: (updates: Partial<QueryState>) => void;
	renderRow: () => JSX.Element[];
	renderHeader: () => JSX.Element[];
	className?: string;
	empty: {
		title: string;
		description: string;
		icon: string;
		action?: () => void;
		href?: string;
	};
};

const Table = ({
	data,
	columns,
	isLoading,
	updateState,
	pagination = initialQueryState,
	renderHeader,
	renderRow,
	className,
	empty,
}: IProps) => {
	const { getTableProps, getTableBodyProps } = useTable({
		columns,
		data,
	});
	const isEmptyList = !data?.length;

	const renderListEmptyComponent = () => (
		<div className="d-flex flex-column flex-center align-items-center">
			<div className="d-flex position-relative d-flex flex-center">
				<KTSVG
					svgClassName="w-150px h-150px"
					path={toAbsoluteUrl('/media/svg/empty.svg')}
					className="mb-7 z-index-10"
				/>
				<i
					style={{ margin: '0 0 0 2px' }}
					className={`icon-${empty?.icon} text-white fs-2 position-absolute z-index-20 mb-11`}
				/>
			</div>
			<span className="mb-2 fs-base fw-bolder text-secondary">
				{empty?.title}
			</span>
			<span className="mb-12 fs-base fw-light text-gray-500">
				{empty?.description}
			</span>
			{empty?.href ? (
				<Button.Link
					size="small"
					title="Create"
					type="primary"
					href={empty?.href}
				/>
			) : empty.action ? (
				<Button
					size="small"
					title="Create"
					type="primary"
					onClick={empty?.action}
				/>
			) : null}
		</div>
	);
	return (
		<div className={clsx('', className)}>
			{!isEmptyList && (
				<div className="table-responsive">
					<table className="table mb-0" {...getTableProps()}>
						<thead className="rounded-3">
							<tr className="bg-gray-100">{renderHeader()}</tr>
						</thead>
						<tbody {...getTableBodyProps()}>
							{!isEmptyList ? renderRow() : null}
						</tbody>
					</table>
				</div>
			)}
			{isEmptyList && empty && !isLoading ? renderListEmptyComponent() : null}
			{isEmptyList ? null : (
				<ListPagination {...{ pagination, isLoading, updateState }} />
			)}
			{isLoading && <Loading />}
		</div>
	);
};

export { Table };
