import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { Navbar } from './Navbar';

export function HeaderWrapper() {
	const { config, classes } = useLayout();
	if (!config.app?.header?.display) {
		return null;
	}

	return (
		<div id="kt_app_header" className="app-header">
			<div
				id="kt_app_header_container"
				className={clsx(
					'app-container flex-lg-grow-1 d-flex flex-fill justify-content-sm-between',
					classes.headerContainer.join(' '),
					config.app?.header?.default?.containerClass
				)}
			>
				{config.app.sidebar?.display && (
					<>
						<div
							className="d-flex align-items-center d-lg-none ms-n2 me-2"
							title="Show sidebar menu"
						>
							<div
								className="btn btn-icon btn-active-color-primary w-35px h-35px"
								id="kt_app_sidebar_mobile_toggle"
							>
								<KTSVG
									path="/media/icons/duotune/abstract/abs015.svg"
									className="svg-icon-1"
								/>
							</div>
							<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
								<Link to="/dashboard" className="d-lg-none">
									<img
										alt="Logo"
										src={toAbsoluteUrl('/media/logos/logo.png')}
										className="h-30px"
									/>
								</Link>
							</div>
						</div>
					</>
				)}

				{!config.app.sidebar?.display && (
					<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15">
						<Link to="/dashboard">
							{config.layoutType !== 'dark-header' ? (
								<img
									alt="Logo"
									src={toAbsoluteUrl('/media/logos/logo.png')}
									className="h-20px h-lg-30px app-sidebar-logo-default"
								/>
							) : (
								<>
									<img
										alt="Logo"
										src={toAbsoluteUrl('/media/logos/logo.png')}
										className="h-20px h-lg-30px app-sidebar-logo-default theme-light-show"
									/>
									<img
										alt="Logo"
										src={toAbsoluteUrl('/media/logos/logo.png')}
										className="h-20px h-lg-30px app-sidebar-logo-default theme-dark-show"
									/>
								</>
							)}
						</Link>
					</div>
				)}
				<Navbar />
			</div>
		</div>
	);
}
