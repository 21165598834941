import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { StylesConfig } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';

import { useAuth } from '../../app/modules/auth';
import type { SelectOptionType } from '../assets/ts';
import { api, baasApi } from '../helpers/apiHelper';
import { useThemeMode } from './layout/theme-mode';

interface IProps {
	className?: string;
	onChange?: (val: Array<string> | string | null) => void;
	count?: number;
	endPoint?: string;
	isSearchable?: boolean;
	keyProp?: string;
	keyValueProp?: string;
	isClearable?: boolean;
	placeholder?: string;
	isMulti?: boolean;
	fetchUserData?: boolean;
	apiService?: 'baas' | 'helpme';
	dataKey?: string;
	paginateKey?: string;
	searchKey?: string;
	disabled?: boolean;
	formValue?: SelectOptionType[];
	// showSelectedValues?: boolean;
}

export const InfiniteSelectPaginate = ({
	className,
	count = 50,
	isMulti = false,
	keyProp,
	keyValueProp,
	endPoint,
	isSearchable,
	isClearable,
	placeholder = 'Search...',
	fetchUserData = false,
	apiService = 'helpme',
	dataKey = 'list',
	paginateKey = 'id',
	searchKey = 'keyword',
	disabled,
	formValue,
	// showSelectedValues = false,
	onChange = () => false,
}: IProps) => {
	const [page, setPage] = useState(1);
	const { mode } = useThemeMode();
	const { userId } = useParams();
	const { currentUser } = useAuth();
	const [selected, setSelected] = useState<SelectOptionType[] | null>(null);
	useEffect(() => {
		if (!Array.isArray(formValue)) return;
		if (formValue.length !== selected?.length) {
			setSelected(formValue);
		}
	}, [formValue]);

	const customStyles = (): StylesConfig<SelectOptionType> => ({
		input: (provided) => ({
			...provided,
			marginLeft: '0',
			padding: 0,
			margin: 0,
		}),
		valueContainer: (provided) => ({
			...provided,
			padding: '0',
			paddingTop: '0.75rem',
			paddingBottom: '0.75rem',
			gap: '0.5rem',
		}),
		container: (provided) => ({
			...provided,
			width: '100%',
		}),
		control: (provided) => ({
			...provided,
			backgroundColor: mode === 'light' ? '#f5f5f5' : '#2b2b40',
			border: 0,
			borderRadius: 12,
			paddingLeft: '1rem',
		}),
	});

	const loadOptions = async (inputValue: string, loadedOptions: any) => {
		const modifiedApi = apiService === 'baas' ? baasApi : api;
		try {
			const response: any = await modifiedApi.get(String(endPoint), {
				params: {
					[searchKey]: inputValue,
					last: loadedOptions.length
						? loadedOptions[loadedOptions.length - 1].paginateKey
						: -1,
					count,
					domain: currentUser?.domain,
					userId: fetchUserData ? userId : '',
				},
			});
			const modifiedResponse =
				apiService === 'baas' ? response.data?.[dataKey] : response?.[dataKey];
			if (modifiedResponse.length) {
				const newOptions = modifiedResponse.map((item: any) => ({
					label: item[keyProp as string],
					value: item[keyValueProp as string],
					paginateKey: item[paginateKey as string],
				}));
				return {
					options: newOptions,
					hasMore: modifiedResponse.length === count,
				};
			}
			return {
				options: [],
				hasMore: false,
			};
		} catch (error) {
			return {
				options: [],
				hasMore: false,
			};
		}
	};

	// console.log(selected, formValue, 'sele');

	return (
		<div className="d-flex flex-column gap-2">
			<AsyncPaginate
				className={clsx('w-full', className)}
				loadOptions={loadOptions}
				debounceTimeout={300}
				additional={{
					page,
				}}
				isDisabled={disabled}
				isMulti={isMulti}
				isClearable={isClearable}
				placeholder={placeholder}
				onInputChange={() => setPage(1)}
				value={selected}
				isSearchable={isSearchable}
				styles={customStyles()}
				onChange={(item) => {
					if (item) {
						if (Array.isArray(item)) {
							onChange(item.map((i) => i.value as string));
							setSelected(item);
						} else {
							onChange(String((item as SelectOptionType)?.value || ''));
							setSelected([item as SelectOptionType]);
						}
					} else {
						onChange(null);
						setSelected(null);
					}
				}}
			/>
			{/* {showSelectedValues ? (
				<div className="d-flex flex-row gap-3 flex-wrap">
					{selected?.length
						? selected?.map(({ label, value }, index) => (
								<button
									disabled={disabled}
									key={index}
									className={clsx(
										'd-flex gap-2 border-0 outline-none flex-row px-3 py-2 flex-center rounded-2 bg-gray-100',
										{
											'cursor-pointer': !disabled,
										}
									)}
									onClick={() =>
										setSelected(selected.filter((i) => i.value !== value))
									}
								>
									<span className="fs-5 text-secondary fw-medium">{label}</span>
									{!disabled && <i className="icon-close text-gray-400 fs-6" />}
								</button>
						  ))
						: null}
				</div>
			) : null} */}
		</div>
	);
};
