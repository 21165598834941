import { DefaultConfig } from '../../../../../_metronic/layout/core';
import { useAuth } from '../../../../../app/modules/auth';
import { SidebarMenuItem } from './SidebarMenuItem';

const SidebarMenu = () => {
	const { isOrganizationOwner } = useAuth();

	const drawerMenuItems =
		DefaultConfig.app?.sidebar?.default?.drawer?.items || [];

	return (
		<div className="app-sidebar-menu overflow-hidden flex-column-fluid">
			<div
				id="kt_app_sidebar_menu_wrapper"
				className="app-sidebar-wrapper hover-scroll-overlay-y"
				data-kt-scroll="true"
				data-kt-scroll-activate="true"
				data-kt-scroll-height="auto"
				data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
				data-kt-scroll-wrappers="#kt_app_sidebar_menu"
				data-kt-scroll-offset="5px"
				data-kt-scroll-save-state="true"
			>
				<div
					className="menu h-100 menu-column py-2 gap-1"
					id="#kt_app_sidebar_menu"
					data-kt-menu="true"
					data-kt-menu-expand="false"
				>
					{drawerMenuItems
						.filter((i) =>
							isOrganizationOwner ? true : i.href !== 'domain-management'
						)
						.map((item) => (
							<SidebarMenuItem {...item} key={item.href} />
						))}
				</div>
			</div>
		</div>
	);
};

export { SidebarMenu };
