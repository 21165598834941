import type { FieldT } from '../../../../_metronic/assets/ts';
import { Button, Form } from '../../../../_metronic/partials';
import { useForm } from '../../../../hooks';

const mobileLoginFields: FieldT[] = [
	{
		name: 'identifier',
		type: 'phoneCountry',
		span: 12,
		label: 'Phone Number',
		validation: 'required|mobile',
	},
	{
		name: 'domain',
		type: 'input',
		span: 12,
		validation: 'required|lowerCaseDigit',
		label: 'Domain',
	},
];

type PropsT = {
	handleCallApi: (values: any) => void;
	isLoading: boolean;
  isForget?:boolean
};
const MobileLogin = ({ handleCallApi, isLoading, isForget }: PropsT) => {
	const { form, handleSubmit } = useForm({
		fields: mobileLoginFields,
		onSubmit: (values) => handleCallApi(values),
	});

	return (
		<div>
			<Form fields={mobileLoginFields} form={form} />
			<Button.Link
				href="/auth"
				type="link"
				title="Login with STP"
				className="w-100 mt-18"
			/>
			<Button
				title={isForget ? "Forget Password" : "Login"}
				onClick={handleSubmit}
				disable={isLoading}
				isLoading={isLoading}
				className="mt-5 w-100"
			/>
		</div>
	);
};

export { MobileLogin };
