import clsx from 'clsx';
import { useEffect, useState } from 'react';

type StatusType = 'inactive' | 'active' | 'mixed';
interface IProps {
	placeholder?: string;
	placeholderClassName?: string;
	className?: string;
	formValue?: StatusType;
	disabled?: boolean;
	onChange?: (val: StatusType) => void;
	onBlur?: () => void;
}

const Checkbox = ({
	placeholder,
	className,
	formValue,
	placeholderClassName,
	disabled = false,
	onBlur = () => false,
	onChange = () => false,
}: IProps): JSX.Element => {
	const [status, setStatus] = useState<StatusType>('inactive');

	useEffect(() => {
		if (!formValue) return;
		if (formValue !== status) setStatus(formValue);
	}, [formValue, status]);

	const handleChange = () => {
		if (disabled) return;
		setStatus(status !== 'inactive' ? 'inactive' : 'active');
		onChange(status !== 'inactive' ? 'inactive' : 'active');
		onBlur();
	};

	return (
		<div
			className={clsx(
				'd-flex gap-3 align-items-center cursor-pointer',
				className
			)}
			onClick={handleChange}
		>
			<span
				className={clsx(
					'box-border w-20px h-20px h-sm-25px w-sm-25px rounded-1 d-flex align-items-center flex-center',
					{
						'border border-gray-300': status === 'inactive' && !disabled,
						'border-none bg-primary': status === 'active' && !disabled,
						'border-0 bg-gray-300': disabled,
					}
				)}
			>
				<i
					className={clsx('icon-Check text-white fs-3 fs-sm-base', {
						'opacity-100': status === 'active',
						'opacity-0': status !== 'active',
					})}
				/>
			</span>
			{placeholder && (
				<span
					className={clsx('fs-5', placeholderClassName, {
						'text-secondary fw-bold': status === 'active',
						'text-gray-400 fw-normal': status !== 'active',
					})}
				>
					{placeholder}
				</span>
			)}
		</div>
	);
};

export { Checkbox };
export type { StatusType };
