import dayjs from 'dayjs';
import type {
	FieldValues,
	RegisterOptions,
	UseFormWatch,
} from 'react-hook-form';

import { textHelper } from './textHelper';

class FormHelper {
	generateRule = (
		validation?: string,
		watch?: UseFormWatch<FieldValues>,
		pattern?: RegExp
	): any => {
		if (validation && typeof validation === 'string') {
			const validations = validation.split('|').map((i) => i.split(':'));
			if (validations.length) {
				const rule: Partial<RegisterOptions> = {};
				validations.forEach(([key, val]) => {
					if (key === 'number') {
						rule.pattern = {
							value: /^(0|[1-9]\d*)(\.\d+)?$/,
							message: 'This field accept numbers only.',
						};
					} else if (key === 'required') {
						rule.required = 'This field is required.';
					} else if (key === 'datepicker') {
						rule.pattern = {
							value: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
							message: 'Select Date Completely',
						};
					} else if (key === 'gte') {
						if (watch && val) {
							rule.validate = {
								isBefore: (v) =>
									!dayjs(v).isBefore(watch(val)) ||
									`This field cant be before ${textHelper.toPascalCase(
										val.split('_').join(' ')
									)}`,
							};
						}
					} else if (key === 'email') {
						rule.pattern = {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: 'Invalid email address.',
						};
					} else if (key === 'min') {
						if (rule.valueAsNumber) {
							rule.min = {
								value: Number(val),
								message: `The minimum is ${val}.`,
							};
						} else {
							rule.minLength = {
								value: Number(val),
								message: `Must be at least ${val} characters.`,
							};
						}
					} else if (key === 'max') {
						if (rule.valueAsNumber) {
							rule.max = {
								value: Number(val),
								message: `The maximum is ${val}.`,
							};
						} else {
							rule.maxLength = {
								value: Number(val),
								message: `Must be a maximum of ${val} characters.`,
							};
						}
					} else if (key === 'deps') {
						const deps = val?.split(',') || [];
						if (watch && deps.length) {
							const watchFields = watch(deps);
							rule.deps = deps.filter((_, index) => watchFields[index]);
						}
					} else if (key === 'match') {
						if (watch && val) {
							rule.validate = {
								match: (v) => v === watch(val) || `Repeat ${val} is wrong.`,
							};
						}
					} else if (key === 'pattern') {
						rule.pattern = {
							value: pattern as RegExp,
							message: 'Invalid social insurance number format',
						};
					} else if (key === 'lowerCaseDigit') {
						rule.pattern = {
							value: /^[a-z0-9]+$/ as RegExp,
							message: 'This field should contain lowerCase letters and digits',
						};
					}
				});
				return rule;
			}
		}
		return null;
	};
}

const formHelper = new FormHelper();
export { formHelper };
