import { useMutation } from 'react-query';

import { useAuth } from '../../../../../app/modules/auth';
import { logoutCurrentUser } from '../../../../../app/modules/auth/core/_requests';

const HeaderUserMenu = () => {
	const { auth, logout, currentUser } = useAuth();

	const { mutate } = useMutation<any, unknown, any>(
		(_data) => logoutCurrentUser(_data),
		{
			onSuccess: () => logout(),
		}
	);

	return (
		<div
			className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-500 menu-state-bg gap-2 fs-5 fw-bold py-4 w-350px"
			data-kt-menu="true"
		>
			<div className="menu-content p-4 mx-3">
				<div className="menu-link d-flex align-items-center gap-4">
					<i className="icon-profile-circle fs-2hx text-gray-400" />
					<span className="fw-bold d-flex align-items-center">
						{currentUser?.email || 'No user data'}
					</span>
				</div>
			</div>
			{/* <Languages /> */}
			<div className="menu-item mx-3">
				<div
					onClick={() => {
						mutate(auth?.access_token);
					}}
					className="menu-link"
				>
					<span className="menu-title text-primary">Log Out</span>
					<i className="menu-icon icon-logout text-primary" />
				</div>
			</div>
		</div>
	);
};

export { HeaderUserMenu };
