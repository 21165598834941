import clsx from 'clsx';
import type { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { checkIsActive } from '../../../../../_metronic/helpers';
import type { DrawerItemT } from '../../../../../_metronic/layout/core';
import { useLayout } from '../../../../../_metronic/layout/core';

const SidebarMenuItem: FC<DrawerItemT> = ({ href, title, icon, className }) => {
	const { pathname } = useLocation();
	const isActive = checkIsActive(pathname, href);
	const { config } = useLayout();
	const { app } = config;

	return (
		<div className={clsx('menu-item', className)}>
			<Link
				className={clsx('menu-link rounded-3 mx-2 justify-content-center', {
					active: isActive,
				})}
				to={`/apps/${href}`}
			>
				{icon && app?.sidebar?.default?.menu?.iconType === 'font' && (
					<i className={clsx('menu-icon fs-2', `icon-${icon}`)} />
				)}
				<span className="fs-6 fw-semibold menu-title text-nowrap">{title}</span>
			</Link>
		</div>
	);
	// ) : null;
};

export { SidebarMenuItem };
