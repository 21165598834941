import { useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import type { Response } from '../_metronic/helpers';
import { api } from '../_metronic/helpers/apiHelper';
import { useAuth } from '../app/modules/auth';

type UseListT = {
	url: string;
	onSettled?: () => void;
	filter?: any & { domain?: string };
	hasPagination?: boolean;
	method?: 'post' | 'get';
	queryKey: string | readonly unknown[];
	enabled?: boolean;
	paginateKey?: string;
	requiredFilter?: boolean;
};
export type ListResponseT<T = any> = {
	list?: Array<T>;
};

const toStringFilters = (items: any) => {
	const modifiedFilters = Object.entries(items).map(([key, value]) => {
		if (value) return `${key}=${value}`;
	});
	if (modifiedFilters)
		return modifiedFilters.filter((i) => i !== undefined).join('&');
	else return '';
};

const useList = <T = any>({
	url,
	filter,
	hasPagination = true,
	method = 'get',
	queryKey,
	enabled = true,
	paginateKey,
	requiredFilter,
}: UseListT) => {
	const [isEnable, setIsEnable] = useState(false);
	const queryClient = useQueryClient();
	const { currentUser } = useAuth();
	const canFetch = useRef<boolean>(true);
	const [last, setLast] = useState<string>('-1');
	const [count, setCount] = useState(50);
	const [filters, setFilters] = useState<any & { domain?: string }>({});
	const state = { last, count, ...(filters || {}) };
	const [query, setQuery] = useState<string>(toStringFilters(state));
	const updatedQuery = useMemo(() => toStringFilters(state), [state]);
	const queryArr = JSON.stringify(query.split('&'));
	const updatedQueryArr = JSON.stringify(updatedQuery.split('&'));
	const modifiedApi = method === 'get' ? api.get : api.post;

	useEffect(() => {
		if (requiredFilter) {
			if (filter) {
				setIsEnable(true);
			} else setIsEnable(false);
		} else setIsEnable(enabled);
	}, []);

	useEffect(() => {
		if (JSON.stringify(filter) !== JSON.stringify(filters)) {
			setFilters(filter);
			setLast('-1');
		}
	}, [filter]);

	useEffect(() => {
		if (queryArr !== updatedQueryArr) {
			queryClient.removeQueries({
				queryKey,
			});
		}
		if (query !== updatedQuery) {
			setQuery(updatedQuery);
		}
	}, [updatedQuery]);

	useEffect(() => {
		if (isEnable) fetchNextPage();
	}, [query]);

	const queryWithDomain = filter?.domain
		? updatedQuery
		: `${updatedQuery}&domain=${currentUser?.domain}`;

	const {
		isFetching,
		isLoading,
		data: list,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery<Response<ListResponseT>>(
		[queryKey],
		async () => {
			return modifiedApi(`${url}?${queryWithDomain}`);
		},
		{
			onSettled: () => {
				canFetch.current = true;
			},
			getPreviousPageParam: (firstPage) => {
				if (firstPage && firstPage.data && firstPage.data.length > 0)
					return firstPage.data[firstPage.data?.length - 1].id;
				else return undefined;
			},
			getNextPageParam: (lastPage) => {
				if (
					lastPage &&
					lastPage.list &&
					lastPage.list.length > 0 &&
					lastPage.list?.length >= count
				)
					return lastPage.list[lastPage.list?.length - 1][paginateKey || 'id'];
				else return undefined;
			},
			cacheTime: 0,
			keepPreviousData: true,
			enabled: isEnable,
		}
	);

	const data = useMemo(() => {
		const res = list?.pages.flatMap((page) => {
			return page.list as T[];
		});
		if (res) return res;
		else return [];
	}, [list]);

	useEffect(() => {
		canFetch.current = true;
	}, [url]);

	return {
		...list,
		data,
		hasNextPage,
		pagination: hasPagination ? !data?.length : false,
		isLoading: isLoading || isFetching,
		isEmpty: !data.length && !(isLoading || isFetching),
		fetchNextPage: () => {
			if (
				canFetch.current &&
				hasPagination &&
				!isLoading &&
				data &&
				data.length >= count
			) {
				setLast(
					String(
						data[data.length - 1][(paginateKey as keyof T) || ('id' as keyof T)]
					)
				);
				canFetch.current = false;
				fetchNextPage();
			}
		},
	};
};

export { useList };
