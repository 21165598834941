import clsx from 'clsx';
import { useEffect, useState } from 'react';
import type {
	ControlProps,
	MenuPlacement,
	Props,
	StylesConfig,
} from 'react-select';
import Select, { components } from 'react-select';

import type { SelectOptionType } from '../assets/ts';

interface IProps {
	placeholder?: string;
	icon?: string;
	className?: string;
	name?: string;
	disabled?: boolean;
	options?: SelectOptionType[];
	formValue?: string | number | null;
	hasError?: boolean;
	onChange?: (val: string | number) => void;
	onBlur?: () => void;
	isClearable?: boolean;
	isSearchable?: boolean;
	menuPlacement?: MenuPlacement;
	theme?: number;
}

type ControlT = ControlProps<SelectOptionType>;

const SelectBox = ({
	placeholder = 'Select ...',
	className,
	name,
	formValue = null,
	options = [],
	onChange = () => false,
	onBlur = () => false,
	disabled,
	isClearable,
	isSearchable = true,
	menuPlacement = 'bottom',
	theme = 1,
}: IProps): JSX.Element => {
	const [selected, setSelected] = useState<SelectOptionType | null>(null);
	const data: SelectOptionType[] = options;
	useEffect(() => {
		if (typeof formValue !== 'string' && typeof formValue !== 'number') return;
		if (formValue !== selected?.value) {
			const finded = data.find((i) => i.value === formValue);
			setSelected(finded || null);
		}
	}, [formValue, data]);

	const customStyles = (): StylesConfig<SelectOptionType> => ({
		input: (provided) => ({
			...provided,
			marginLeft: '0',
			padding: 0,
			margin: 0,
		}),
	});

	const customFilter = (option: SelectOptionType, searchText: string) => {
		if (option.label.toLowerCase().startsWith(searchText.toLowerCase())) {
			return true;
		}
		return false;
	};
	const CustomSelect = (props: Props<SelectOptionType, false>) => {
		return <Select menuShouldScrollIntoView={false} {...props} />;
	};

	const Control = (props: ControlT) => (
		<components.Control
			{...props}
			getStyles={() => ({ margin: 0, minHeight: '48px', zIndex: 10000 })}
			className={clsx(
				'gap-x-6 d-flex align-items-center px-2 cursor-pointer rounded-3 bg-gray-100'
			)}
		>
			{props.children}
		</components.Control>
	);

	// className={clsx(
	// 		'form-select placeholder-light-primary form-select-solid fs-5 fw-normal',
	// 		{
	// 			'text-secondary': theme === 1,
	// 			'bg-light-primary text-primary': theme === 2,
	// 		},
	// 		className
	// 	)}

	return (
		<CustomSelect
			isSearchable={isSearchable}
			isClearable={isClearable}
			menuPosition="absolute"
			menuPlacement={menuPlacement}
			menuShouldScrollIntoView={false}
			options={data}
			filterOption={customFilter}
			value={selected}
			components={{ Control, IndicatorSeparator: null }}
			placeholder={placeholder}
			isDisabled={disabled}
			className={clsx('', className)}
			name={name}
			styles={customStyles()}
			blurInputOnSelect
			onBlur={onBlur}
			onChange={(item) => {
				onChange(item?.value || '');
			}}
		/>
	);
};

export { SelectBox };
