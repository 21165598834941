import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import type { FieldT } from '../../../../_metronic/assets/ts';
import { Button, Form } from '../../../../_metronic/partials';
import { useForm } from '../../../../hooks/useForm';
import { setNewPassword } from '../core/_requests';
import { useAuth } from '../core/Auth';

const passwordFields: FieldT[] = [
	{
		name: 'password',
		label: 'New Password',
		type: 'input',
		inputType: 'password',
		validation: 'required|deps:retype-password',
	},
	{
		name: 'retype-password',
		label: 'Re-Type New Password',
		type: 'input',
		inputType: 'password',
		validation: 'required|match:password',
	},
];

const NewPassword = () => {
	const { otpData } = useAuth();
	const navigate = useNavigate();
	const { mutate, isLoading } = useMutation<any, unknown, any>(
		(_data) => setNewPassword(_data),
		{
			onSuccess: () => {
				toast.success('Your Password Succesfully Changed');
				navigate('/auth');
			},
		}
	);

	const { form, handleSubmit } = useForm({
		fields: passwordFields,
		onSubmit: (values) => {
			mutate({
				password: values.password,
				regId: otpData?.registration_id,
			});
		},
	});

	useEffect(() => {
		if (!otpData.identifier) navigate('/auth');
	}, []);

	return (
		<div className="form w-100 w-sm-300px">
			<Form form={form} fields={passwordFields} onSubmit={handleSubmit} />
			<Button
				title="Submit"
				onClick={handleSubmit}
				disable={isLoading}
				isLoading={isLoading}
				className="mt-18 w-100"
			/>
		</div>
	);
};

export { NewPassword };
