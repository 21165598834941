import type { FC } from 'react';
import type React from 'react';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';

import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import type { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';

const ConfigurationRoutes = lazy(
	() => import('../modules/apps/configuration/ConfigurationRoutes')
);
const UsersPage = lazy(
	() => import('../modules/apps/user-management/UserManagementRoutes')
);
const WorkGroupRoutes = lazy(
	() => import('../modules/apps/work-group/WorkGroupRoutes')
);
const PushNotificationRoutes = lazy(
	() => import('../modules/apps/push-notification/PushNotificationRoutes')
);
const CaseManagementRoutes = lazy(
	() => import('../modules/apps/case-management/CaseManagementRoutes')
);
const TemplateManagerRoutes = lazy(
	() => import('../modules/apps/template-management/TemplateManagerRoutes')
);
const DomainManagementRoutes = lazy(
	() => import('../modules/apps/domain-management/DomainManagementRoutes')
);
const OrganizationRoutes = lazy(
	() => import('../modules/apps/organization/OrganizationRoutes')
);
type PrivateRouteT = {
	path: string;
	Component: React.FC;
};
const PRIVATE_ROUTES: PrivateRouteT[] = [
	{ path: 'configuration/*', Component: ConfigurationRoutes },
	{ path: 'user-management/*', Component: UsersPage },
	{ path: 'work-group/*', Component: WorkGroupRoutes },
	{
		path: 'notifications/*',
		Component: PushNotificationRoutes,
	},
	{
		path: 'case-management/*',
		Component: CaseManagementRoutes,
	},
	{
		path: 'template-manager/*',
		Component: TemplateManagerRoutes,
	},
	{
		path: 'domain-management/*',
		Component: DomainManagementRoutes,
	},
	{
		path: 'organization/*',
		Component: OrganizationRoutes,
	},
];

const PrivateRoutes = () => {
	const SuspensedView: FC<WithChildren> = ({ children }) => {
		const baseColor = getCSSVariableValue('--kt-primary');
		TopBarProgress.config({
			barColors: {
				'0': baseColor,
			},
			barThickness: 1,
			shadowBlur: 5,
		});
		return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
	};

	return (
		<Routes>
			<Route element={<MasterLayout />}>
				<Route index element={<Navigate to="/apps/user-management/users" />} />
				<Route
					path="auth/*"
					element={<Navigate to="/apps/user-management/users" />}
				/>
				{PRIVATE_ROUTES.map(({ Component, path }) => (
					<Route
						key={path}
						path={`/apps/${path}`}
						element={
							<SuspensedView>
								<Component />
							</SuspensedView>
						}
					/>
				))}
				<Route path="*" element={<Navigate to="/error/404" />} />
			</Route>
		</Routes>
	);
};

export { PrivateRoutes };
