import qs from 'qs';
import type { Dispatch, SetStateAction } from 'react';
import { createContext, useEffect, useState } from 'react';

import type { DomainT } from '../../../app/modules/apps/domain-management/domain-list/core/_models';
import type {
	ID,
	InfiniteQueryResponseContextProps,
	QueryResponseContextProps,
	QueryState,
} from './models';

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
	return createContext(initialState);
}

function createInfiniteResponseContext<T>(
	initialState: InfiniteQueryResponseContextProps<T>
) {
	return createContext(initialState);
}

function isNotEmpty(obj: unknown) {
	return obj !== undefined && obj !== null && obj !== '';
}

function stringifyRequestQuery(state: QueryState): string {
	const pagination = qs.stringify(state, {
		filter: ['last', 'page', 'count'],
		skipNulls: true,
	});
	const sort = qs.stringify(state, {
		filter: ['sortBy', 'order'],
		skipNulls: true,
	});
	const search = isNotEmpty(state.search)
		? qs.stringify(state, { filter: ['search'], skipNulls: true })
		: '';
	const domain = isNotEmpty(state.domain)
		? qs.stringify(state, { filter: ['domain'], skipNulls: true })
		: '';
	const filter = state.filter
		? Object.entries(state.filter as Object)
				.filter((obj) => isNotEmpty(obj[1]))
				.map((obj) => {
					return `filter_${obj[0]}=${obj[1]}`;
				})
				.join('&')
		: '';

	return [pagination, sort, search, filter, domain].filter((f) => f).join('&');
	// .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
	const cache: unknown = qs.parse(query);
	const filters = Object.entries(cache as Object)
		.filter(([key, value]) => {
			if (key.includes('filter_')) {
				return true;
			}
			if (key.includes('search')) {
				return true;
			}
			if (key.includes('domain')) {
				return true;
			}
			return false;
		})
		.flatMap(([key, value]) => {
			if (key.includes('filter_')) {
				const newKey = key.replace('filter_', '');
				return { [newKey]: value };
			}
			if (key.includes('search')) {
				return { ['search']: value };
			}
			if (key.includes('domain')) {
				return { ['domain']: value };
			}
		});

	return {
		...(cache as QueryState),
		filter: { ...Object.assign({}, ...filters) },
	};
}

function calculatedGroupingIsDisabled<T>(
	isLoading: boolean,
	data: Array<T> | undefined
): boolean {
	if (isLoading) {
		return true;
	}

	return !data || !data.length;
}

function calculateIsAllDataSelected<T>(
	data: Array<T> | undefined,
	selected: Array<ID>
): boolean {
	if (!data) {
		return false;
	}

	return data.length > 0 && data.length === selected.length;
}

function groupingOnSelect(
	id: ID,
	selected: Array<ID>,
	setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
	if (!id) {
		return;
	}

	if (selected.includes(id)) {
		setSelected(selected.filter((itemId) => itemId !== id));
	} else {
		const updatedSelected = [...selected];
		updatedSelected.push(id);
		setSelected(updatedSelected);
	}
}

function groupingOnSelectAll<T>(
	isAllSelected: boolean,
	setSelected: Dispatch<SetStateAction<Array<ID>>>,
	data?: Array<T & { id?: ID }>
) {
	if (isAllSelected) {
		setSelected([]);
		return;
	}

	if (!data || !data.length) {
		return;
	}

	setSelected(data.filter((item) => item.id).map((item) => item.id));
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay] // Only re-call effect if value or delay changes
	);
	return debouncedValue;
}

const DOMAIN_LOCAL_STORAGE_KEY = 'kt-domain-react-v';
const getDomain = (): DomainT | undefined => {
	if (!localStorage) {
		return;
	}

	const lsValue: string | null = localStorage.getItem(DOMAIN_LOCAL_STORAGE_KEY);
	if (!lsValue) {
		return;
	}

	try {
		const domain: DomainT = JSON.parse(lsValue) as DomainT;
		if (domain) {
			return domain;
		}
	} catch (error) {
		console.error('DOMAIN LOCAL STORAGE PARSE ERROR', error);
	}
};

const setDomain = (domain: DomainT) => {
	if (!localStorage) {
		return;
	}

	try {
		const lsValue = JSON.stringify(domain);
		localStorage.setItem(DOMAIN_LOCAL_STORAGE_KEY, lsValue);
	} catch (error) {
		console.error('DOMAIN LOCAL STORAGE SAVE ERROR', error);
	}
};

export {
	calculatedGroupingIsDisabled,
	calculateIsAllDataSelected,
	createInfiniteResponseContext,
	createResponseContext,
	getDomain,
	groupingOnSelect,
	groupingOnSelectAll,
	isNotEmpty,
	parseRequestQuery,
	setDomain,
	stringifyRequestQuery,
	useDebounce,
};
