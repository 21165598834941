import { useEffect, useRef, useState } from 'react';

const useCountdown = (time: number) => {
	const [secondsLeft, setSecondsLeft] = useState<number>(time);
	const intervalRef = useRef<number>();
	const minutes = Math.floor(secondsLeft / 60);
	const seconds = secondsLeft - minutes * 60;

	const decreaseNum = () => setSecondsLeft((prev) => prev - 1);

	const stringPadLeft = (value: number) => {
		return [...Array(2)].join(`0${value}`).slice(-2);
	};

	useEffect(() => {
		setSecondsLeft(time);
	}, [time]);

	useEffect(() => {
		if (secondsLeft) {
			intervalRef.current = window.setInterval(decreaseNum, 1000);
		}
		return () => clearInterval(intervalRef.current);
	}, [secondsLeft]);

	const setSeconds = (newTime: number) => setSecondsLeft(newTime);
	const timeLeft = `${stringPadLeft(minutes)}:${stringPadLeft(seconds)}`;
	return { timeLeft, secondsLeft, setSeconds };
};

export { useCountdown };
