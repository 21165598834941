import clsx from 'clsx';
import { useEffect, useState } from 'react';

import type { LabelValue } from '../assets/ts';
import { Checkbox } from './Checkbox';

type IProps = {
	disabled?: boolean;
	options: LabelValue[];
	className?: string;
	itemClassName?: string;
	formValue?: Array<string | number> | null;
	onChange?: (val: Array<string | number>) => void;
};

const CheckboxGroup = ({
	options,
	onChange = () => false,
	className,
	disabled,
	formValue,
	itemClassName,
}: IProps): JSX.Element => {
	const [value, setValue] = useState<Array<string | number>>([]);

	useEffect(() => {
		if (!Array.isArray(formValue)) return;
		if (JSON.stringify(formValue) !== JSON.stringify(value)) {
			setValue(formValue);
		}
	}, [formValue, options]);

	const handleChange = (key: string | number, isActive: boolean) => {
		if (disabled) return;
		const newValue = isActive
			? [...value, key]
			: value.filter((i) => key !== i);
		setValue(newValue);
		onChange(newValue);
	};

	return (
		<div className={clsx('d-flex', className)}>
			{options.map(({ label, value: val }) => (
				<Checkbox
					className={itemClassName}
					key={val}
					formValue={value.includes(val) ? 'active' : 'inactive'}
					placeholder={label}
					onChange={(status) => handleChange(val, status === 'active')}
				/>
			))}
		</div>
	);
};

export { CheckboxGroup };
