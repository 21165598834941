import clsx from 'clsx';

import { KTCard } from '../helpers';
import { ToolbarWrapper } from '../layout/components/toolbar';
import type { PageLink } from '../layout/core';
import { PageTitle } from '../layout/core';
import { Spinner } from './Spinner';

type Props = {
	children: React.ReactNode;
	title?: string;
	isLoading?: boolean;
	breadcrumb?: Array<PageLink>;
	className?: string;
	permissionKey?: string;
	contentClassName?: string;
	titleWrapperClassName?: string;
};

const AppWrapper = ({
	children,
	breadcrumb,
	title,
	className,
	contentClassName,
	titleWrapperClassName,
	isLoading,
}: Props) => {
	return (
		<KTCard className={clsx('d-flex flex-column', className)}>
			<div className={clsx(titleWrapperClassName)}>
				<ToolbarWrapper />
				<PageTitle breadcrumbs={breadcrumb}>{title}</PageTitle>
			</div>
			<div
				className={clsx('', {
					[`${contentClassName}`]: !isLoading,
					'align-items-center flex-center d-flex': isLoading,
				})}
			>
				{isLoading ? (
					<Spinner isLoading={isLoading} className="py-4" />
				) : (
					children
				)}
			</div>
		</KTCard>
	);
};

export { AppWrapper };
