import type { Response } from '../../../../../_metronic/helpers';

export type OrganzationOwnerT = {
	phone_number: string;
	email: string;
	id: string;
};

export type GetDomainAdminsArgs = {
	user_ids: string[];
	domain?: string;
};

export type UserRolesResponseT = {
	domain: string;
	application: string;
	user_id: string;
	user_obj: {
		id: string;
		email: string;
		phone_number: string;
	};
	role: string;
};

export type OrganizationT = {
	id: string;
	identifier: string;
	owner: OrganzationOwnerT;
	name: string;
	description: string;
	logo: string;
	status: string;
	website: string;
	domains: string[];
};

export type OrganizationResponse = {
	organizations?: Array<OrganizationT>;
};

export type OrganizationQueryResponse = Response<OrganizationResponse>;

export const initialOrganization: OrganizationT = {
	id: '',
	identifier: '',
	owner: {
		email: '',
		phone_number: '',
		id: '',
	},
	name: '',
	description: '',
	logo: '',
	status: '',
	website: '',
	domains: [],
};
