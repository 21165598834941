import dayjs, { extend } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';

interface IDateType {
	showDate: (date: string | undefined | null) => string | null;
	reverseDate: (date: string | undefined | null) => string | null;
	showTime: (date: string | undefined | null) => string | null;
	showMonth: (date: string | undefined | null) => string | null;
	showMonthWithSpace: (
		date: string | undefined | null | number
	) => string | null;
	showSeconds: (date: string | undefined | null) => string | null;
	showMonthYear: (date: string | undefined | null) => string | null;
	showMinute: (date: string | undefined | null) => string | null;
	showOnlyMinute: (date: string | undefined | null) => string | null;
	showIso: (date: string | undefined) => string | null;
	convertToUtc: (date: string | undefined) => string | null;
}

class DateHelper implements IDateType {
	constructor() {
		extend(customParseFormat);
		extend(utc);
	}

	showDate(date: string | undefined | null): string | null {
		if (!date) return null;
		return dayjs(date).format('DD-MM-YYYY');
	}

	showTime(date: string | undefined | null): string | null {
		if (!date) return null;
		return dayjs(date).format('HH:mm  DD MMM YYYY');
	}

	reverseDate(date: string | undefined | null): string | null {
		if (!date) return null;
		return dayjs(date).format('YYYY-MM-DD');
	}

	showMonth(date: string | undefined | null): string | null {
		if (!date) return null;
		return dayjs(date).format('DD-MMM-YYYY');
	}

	showMonthWithSpace(date: string | undefined | null | number): string | null {
		if (!date) return null;
		return dayjs(date).format('DD MMM YYYY');
	}

	showSeconds(date: string | undefined | null): string | null {
		if (!date) return null;
		return dayjs(date).format('DD MMM YYYY , HH:mm:ss');
	}

	showMinute(date: string | undefined | null): string | null {
		if (!date || !date.length) return null;
		const newDate = dayjs(date).format('DD MMM YYYY - HH:mm');
		return newDate;
	}

	showOnlyMinute(date: string | undefined | null): string | null {
		if (!date) return null;
		return dayjs(date).format('HH:mm');
	}

	showMonthYear(date: string | undefined | null): string | null {
		if (!date) return null;
		return dayjs(date).format('MMM YYYY');
	}

	showIso(date: string | undefined): string | null {
		const newData = dayjs(date, 'YYYY MM DD HH:mm').format(
			'DD MMM YYYY - HH:mm'
		);
		return newData;
	}

	convertToUtc(date: string | undefined): string | null {
		if (!date) return null;
		return dayjs(date).utc().format();
	}

	convertToIsoDate(date: string) {
		const isIsoDate = date.includes('T');
		if (isIsoDate) return date;
		else return `${date}T23:59:00Z`;
	}
}

const dateHelper = new DateHelper();
export { dateHelper };
